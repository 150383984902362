import './headerFooterLayout.scss';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';

function HeaderFooterLayout({ children, bodyWidth }) {
  return (
    <div className='header_footer_layout_wrapper'>
      <Header />
      <div className='hf_layout_body'>
        <div className='hf_layout_content' style={{ width: bodyWidth || '' }}>
          {children}
          {window.innerWidth < 1020 ? <Footer /> : null}
        </div>
      </div>
      {window.innerWidth > 1020 ? <Footer /> : null}
    </div>
  );
}

export default HeaderFooterLayout;
