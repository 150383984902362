import './errorPage.scss';
import Header from '../../components/Header/header';
import Button from '../../components/Button/button';
import lifeBuoy from '../../statics/images/life_buoy.jpg';
import { useNavigate } from 'react-router-dom';

function ErrorPage({ errorHeader, primarySubtext, secondarySubtext, tertiarySubtext, buttonLabel, rerouteUrl }) {
  const buttonWidth = { width: '200px', margin: '32px auto' };
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className='error_page_wrapper'>
        <div className='error_left_side'>
          <div className='error_main_title'>{errorHeader}</div>
          <div className='error_subtext'>{primarySubtext}</div>
          <div className='error_subtext'>{secondarySubtext}</div>
          <div className='error_subtext'>{tertiarySubtext}</div>
          <Button
            buttonLabel={buttonLabel}
            styleClass={'secondaryButtonStyling'}
            buttonStyling={buttonWidth}
            onClick={() => navigate(rerouteUrl)}
          />
        </div>
        <div className='error_right_side'>
          <img src={lifeBuoy} className='error_img_styling' alt='life_buoy' />
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
