import './stepper.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import RadialSeparators from './RadialSeparators';
import 'react-circular-progressbar/dist/styles.css';

function Stepper({
  currentStep,
  primaryColor,
  secondaryColor,
  totalSteps,
  containerSize,
  showMobileStepper,
  disabledColor,
  lineStylingMargin,
}) {
  const currentStepIndex = totalSteps.map(t => t.step).indexOf(currentStep);
  const containerSizeVariable = window.innerWidth < 560 ? 3.0 : 1.5;
  const stepWidth = `${containerSize / containerSizeVariable / totalSteps.length}px`;
  const getCurrentStepLabel = totalSteps[currentStepIndex].label;

  const calculateWidthByStep = step => {
    return step === 0 ? (window.innerWidth < 560 ? '60px' : '') : '';
  };

  const calculateProgressBar = () => {
    return ((currentStepIndex + 1) / totalSteps.length) * 100;
  };

  const getNextStep = () => {
    return totalSteps[currentStepIndex + 1].label;
  };

  const getCurrentIcon = () => {
    return totalSteps[currentStepIndex].icon;
  };

  const getNextStepIcon = () => {
    return totalSteps[currentStepIndex + 1].icon;
  };

  const getActiveStepByIndex = index => {
    return index <= currentStepIndex || (currentStepIndex === -1 && !('CANCELED', 'DENIED').includes(currentStep));
  };

  return (
    <div>
      {showMobileStepper && window.innerWidth < 600 ? (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginLeft: '24px' }}>
          <div style={{ width: '60px', height: '60px', marginRight: '12px' }}>
            <CircularProgressbarWithChildren
              value={calculateProgressBar()}
              styles={buildStyles({ pathColor: '#36CBD9', strokeLinecap: 'butt' })}
            >
              <RadialSeparators
                count={6}
                style={{
                  background: '#fff',
                  width: '2px',
                  height: `${10}%`,
                }}
              />
              <div style={{ fontSize: 14, fontWeight: 700 }}>
                {currentStepIndex + 1} of {totalSteps.length}
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon
                icon={getCurrentIcon()}
                style={{
                  '--fa-primary-color': `${primaryColor}`,
                  '--fa-secondary-color': `${secondaryColor}`,
                  '--fa-secondary-opacity': '1',
                  fontSize: '16px',
                  marginRight: '5px',
                }}
              />
              <div style={{ fontWeight: 700, fontSize: '14px', marginTop: '2px' }}>{getCurrentStepLabel}</div>
            </div>
            {currentStepIndex + 1 === totalSteps.length ? null : (
              <div style={{ color: '#5F6267', fontSize: '13px' }}>
                <span style={{ fontWeight: '700' }}>Next: </span>
                <span>{getNextStep()}</span>
                <FontAwesomeIcon
                  icon={getNextStepIcon()}
                  style={{
                    '--fa-primary-color': `${primaryColor}`,
                    '--fa-secondary-color': `${secondaryColor}`,
                    '--fa-secondary-opacity': '1',
                    fontSize: '16px',
                    marginLeft: '5px',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='stepper-wrapper'>
          {totalSteps.map((element, index) => {
            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center', width: calculateWidthByStep(index) }}>
                {![0, totalSteps.length].includes(index) ? (
                  <div
                    className='line-styling'
                    style={{
                      backgroundColor: getActiveStepByIndex(index) ? primaryColor : disabledColor,
                      width: stepWidth,
                      margin: lineStylingMargin,
                    }}
                  />
                ) : null}
                <div
                  className='icon-with-label'
                  style={{ color: getActiveStepByIndex(index) ? primaryColor : disabledColor }}
                >
                  <div data-testid={element.icon}>
                    <FontAwesomeIcon
                      icon={element.icon}
                      style={{
                        '--fa-primary-color': getActiveStepByIndex(index) ? `${primaryColor}` : `${disabledColor}`,
                        '--fa-secondary-color': getActiveStepByIndex(index) ? `${secondaryColor}` : `${disabledColor}`,
                        '--fa-secondary-opacity': '1',
                        fontSize: '16px',
                      }}
                    />
                  </div>
                  <div className='stepper_label' style={{ margin: element.label === 'Analysis' ? '0 15px' : '0' }}>
                    {element.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Stepper;
