const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL_TEST_REQUESTS':
      return {
        ...state,
        testRequests: action.payload,
      };
    case 'SET_CURRENT_TEST_REQUEST':
      return {
        ...state,
        currentTestRequest: action.payload,
      };
    case 'SET_KEYCLOAK':
      return {
        ...state,
        keycloak: action.payload,
      };
    case 'SET_REQUEST_INFO':
      return {
        ...state,
        testRequestInfo: action.payload,
      };
    case 'SET_USER_INFO':
      return {
        ...state,
        userInfo: action.payload,
      };
    case 'SET_GC_ACCESS_CODE':
      return {
        ...state,
        gcAccessCode: action.payload,
      };
    case 'SET_STRIPE_PAYMENT_INFO':
      return {
        ...state,
        stripePaymentInfo: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
