import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import Button from '../../components/Button/button';
import DataDisplayCell from '../../components/DataDisplayCell/dataDisplayCell';
import { PRODUCT_NOTIFICATIONS_CONSENT } from '../../constants/constants';
import personalGenome from '../../statics/images/personal_genome.png';
import { Context } from '../../store/store';
import { recordGoogleAnalyticsEvent, recordGoogleAnalyticsSend } from '../../utils/helpers';
import './pit_styling.scss';

function ReviewAndSubmit({ onBtnClick, onBackClick, onPlaceOrder }) {
  const [state, dispatch] = useContext(Context);
  const [isChecked, setIsChecked] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const formik = useFormik({
    initialValues: {
      subject: state.testRequestInfo.subject,
    },
    validationSchema: Yup.object({
      subject: Yup.object({
        contact_info: Yup.array().of(
          Yup.object().shape({
            address: Yup.object({
              city: Yup.string().required('Required'),
              postal_code: Yup.string()
                .required('Required')
                .matches(/^[0-9]+$/, 'Invalid Zip Code')
                .length(5, 'Invalid Zip Code'),
              region: Yup.string().required('Required'),
              street_address: Yup.string().required('Required'),
            }),
            email: Yup.string(),
          }),
        ),
        name: Yup.object({
          first: Yup.string().required('Required'),
          last: Yup.string().required('Required'),
        }),
      }),
    }),
    onSubmit: async values => {
      setDisableSubmitBtn(true);
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Next Button: Review & Submit');
      await onBtnClick(values);
      onPlaceOrder();
    },
  });

  useEffect(() => {
    recordGoogleAnalyticsSend('pageview', '/order?step=review', 'PIT Flow: Review & Submit');
  }, []);

  const handleMarketingConsent = event => {
    setIsChecked(event.target.checked);
    if (event.target.checked === true) {
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: {
          ...state.testRequestInfo,
          consents_granted: [...state.testRequestInfo.consents_granted, PRODUCT_NOTIFICATIONS_CONSENT],
        },
      });
    } else {
      // if marketing consent is selected, then we need to remove it
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: {
          ...state.testRequestInfo,
          consents_granted: state.testRequestInfo.consents_granted.includes(PRODUCT_NOTIFICATIONS_CONSENT)
            ? state.testRequestInfo.consents_granted.filter(value => value !== PRODUCT_NOTIFICATIONS_CONSENT)
            : [...state.testRequestInfo.consents_granted, PRODUCT_NOTIFICATIONS_CONSENT],
        },
      });
    }
  };

  const displayPatientData = () => {
    return (
      <div className='review_patient_data_cell'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DataDisplayCell
            dataLabel='Patient Name'
            dataValue={
              state.testRequestInfo.subject.name.first +
              (state.testRequestInfo.subject.name.middle
                ? ' ' + state.testRequestInfo.subject.name.middle + ' '
                : ' ') +
              state.testRequestInfo.subject.name.last
            }
          />
          <DataDisplayCell dataLabel='Patient Email' dataValue={state.testRequestInfo.subject.contact_info[0].email} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DataDisplayCell
            dataLabel='Patient Address'
            multiline={true}
            multiLine1={state.testRequestInfo.subject.contact_info[0].address.street_address}
            multiLine2={
              state.testRequestInfo.subject.contact_info[0].address.city +
              ', ' +
              state.testRequestInfo.subject.contact_info[0].address.region
            }
            multiLine3={state.testRequestInfo.subject.contact_info[0].address.postal_code}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='questionnaire_wrapper' style={{ alignItems: 'center', maxWidth: '700px' }}>
      <div className='pit_flow_title' style={{ alignSelf: 'center', marginBottom: '18px' }}>
        Please review your order
      </div>
      <div className='review_and_submit_cell'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <img src={personalGenome} alt='logo' className='white-checkout-genome-logo' />
          </div>
          <div style={{ fontWeight: '700', fontSize: '16px', color: '#5F6267', marginTop: '10px' }}>
            ${Number(state.stripePaymentInfo.total_cost).toFixed(2)}
          </div>
        </div>
        <ul style={{ fontSize: '14px', lineHeight: '1.9', textAlign: 'left', margin: '8px 0 0 15px' }}>
          <li>Clinical grade 30x whole-genome DNA sequencing that can be reanalyzed for future tests with MyOme</li>
          <li>Proactive Health Report, 81 Genes</li>
          <li>Pharmacogenomics Report</li>
          <li>Clinician order review with DNAvisit</li>
          <li>Post-test genetic counseling to help you understand your results</li>
          <li>Access to your results through MyOme’s secure patient portal</li>
        </ul>
        <div className='order_cell_divider' style={{ width: 'inherit' }} />
        <div className='patient_info_header'>
          <div style={{ fontWeight: '600' }}>Patient Information</div>
        </div>
        {displayPatientData()}
        <div className='order_cell_divider' style={{ width: 'inherit', margin: '18px 5px' }} />
        <div style={{ fontWeight: '600', marginBottom: '18px', alignSelf: 'flex-start', paddingLeft: '18px' }}>
          Family Questionnaire
        </div>
        {Object.keys(state.testRequestInfo.family_history).map(questionId => (
          <div
            key={questionId}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'space-between',
              padding: '0 18px',
              marginBottom: '18px',
            }}
          >
            <div style={{ fontSize: '12px', textAlign: 'left' }}>
              {state.testRequestInfo.family_history[questionId].question_text}
            </div>
            <div style={{ display: 'flex', marginTop: '12px' }}>
              <div style={{ fontSize: '12px', fontWeight: '700', textAlign: 'left' }}>Answer: </div>
              <div style={{ fontSize: '12px', textAlign: 'left', marginLeft: '5px' }}>
                {state.testRequestInfo.family_history[questionId].response}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Form.Check
        style={{ fontSize: '14px', textAlign: 'left', marginTop: '18px' }}
        type='checkbox'
        checked={isChecked}
        label=' Please notify me of new tests and genetic insights that become available using the
            30X whole-genome sequence data collected with my MyOme Personal Genome™ order.'
        onChange={handleMarketingConsent}
      />
      <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'center' }}>
        <Button
          type='button'
          buttonLabel='Previous'
          styleClass='secondaryButtonStyling'
          buttonStyling={{ marginRight: '18px' }}
          onClick={() => onBackClick('consents')}
        />
        <Button
          type='button'
          buttonLabel='Place Order'
          dataTestId='placeOrderBtn'
          styleClass='primaryButtonStyling'
          disabled={disableSubmitBtn}
          onClick={() => formik.handleSubmit()}
        />
      </div>
    </div>
  );
}

export default ReviewAndSubmit;
