import '../../stylesheets/common.scss';
import './login.scss';
import Header from '../../components/Header/header';
import Button from '../../components/Button/button';

import loginLgImage from '@statics/images/login-images/login-lg.webp';
import loginXlImage from '@statics/images/login-images/login-xl.webp';
import loginXsImage from '@statics/images/login-images/login-xs.webp';
import loginSmImage from '@statics/images/login-images/login-sm.webp';
import loginMdImage from '@statics/images/login-images/login-md.webp';

import { useContext } from 'react';
import { Context } from '../../store/store';

function Login() {
  const [state] = useContext(Context);

  return (
    <div data-testid='login_page'>
      <Header />
      <div className='login-page-wrapper'>
        <div className='login-left-half'>
          <img
            data-sizes='auto'
            alt='patient login image'
            src={loginXlImage}
            srcSet={`
              ${loginXsImage} 440w,
              ${loginSmImage} 767w,
              ${loginMdImage} 991w,
              ${loginLgImage} 1000w,
              ${loginXlImage} 1236w
            `}
            className='login-img'
          />
        </div>
        <div className='login-right-half'>
          <div className='right-half-content'>
            <div className='login-title'>
              One Test <br /> Whole Genome
            </div>
            <Button
              dataTestId='loginBtn'
              onClick={() => state.keycloak.login({ redirectUri: window.location.origin + '/home' })}
              buttonLabel={localStorage.getItem('access_token') ? 'Go to Home' : 'Login'}
              styleClass='loginButtonStyling'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
