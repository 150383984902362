import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './pit_styling.scss';
import Button from '../../components/Button/button';
import { useContext, useEffect } from 'react';
import { recordGoogleAnalyticsEvent, recordGoogleAnalyticsSend } from '../../utils/helpers';
import { Context } from '../../store/store';

function WhatsNext() {
  const [state] = useContext(Context);

  useEffect(() => {
    recordGoogleAnalyticsSend('pageview', '/order?step=whats_next', "PIT Flow: What's Next");
  }, []);

  const onAccountSetUp = () => {
    recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Set up your account: Whats Next');
    const registrationUrl = state.keycloak.createRegisterUrl();
    window.location.href = registrationUrl;
  };

  return (
    <div className='questionnaire_wrapper'>
      <div className='pit_flow_title' style={{ alignSelf: 'center', marginBottom: '18px' }}>
        Your order has been submitted for review!
      </div>
      <div className='review_and_submit_cell' style={{ padding: '24px 24px 0 24px' }}>
        <div
          style={{
            borderBottom: '1px solid #DADEE4',
            margin: '0 18px',
            color: '#36CBD9',
            fontSize: '22px',
            fontWeight: '600',
            padding: '0 0 18px 0',
          }}
        >
          What Happens Next?
        </div>
        <div className='whats_next_body'>
          <div className='icon_text_wrapper'>
            <FontAwesomeIcon icon={'fa-regular fa-user-check'} className='whats_next_icon' />
            <div className='whats_next_text'>Finish setting up your account by clicking on the button below</div>
          </div>
          <div className='icon_text_wrapper'>
            <FontAwesomeIcon icon={'fa-solid fa-laptop-medical'} className='whats_next_icon' />
            <div className='whats_next_text'>Login to our patient&apos;s portal to keep track of your request</div>
          </div>
          <div className='icon_text_wrapper'>
            <FontAwesomeIcon icon={'fa-regular fa-envelope'} className='whats_next_icon' />
            <div className='whats_next_text'>We will be sending you updates about your request via email</div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'center' }}>
        <Button
          buttonLabel='Finish setting up your account'
          styleClass='secondaryButtonStyling'
          onClick={() => onAccountSetUp()}
        />
      </div>
    </div>
  );
}

export default WhatsNext;
