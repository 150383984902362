import './orderCard.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash/get';
import { ORDER_STATUS_STEPS, PRODUCT_NAMES } from '../../../constants/constants';
import { formatDate } from '../../../utils/helpers';
import DataDisplayCell from '../../DataDisplayCell/dataDisplayCell';

function OrderCard({ testRequest, onClick }) {
  const currentStatus = ORDER_STATUS_STEPS.find(o => o.step === testRequest.orders[0].status);
  const getDisplayStatus = currentStatus?.label ?? testRequest.orders[0].status;

  return (
    <div className='order_card_wrapper' onClick={onClick} data-testid='orderCardWrapper'>
      <div className='order_top_half'>
        <div className='dna_icon_wrapper'>
          <FontAwesomeIcon
            icon={'fa-duotone fa-dna'}
            style={{
              '--fa-primary-color': '#36cbd9',
              '--fa-secondary-color': '#003595',
              '--fa-secondary-opacity': '1',
              fontSize: '28px',
            }}
          />
        </div>
        <div className='order_card_header'>
          <div style={{ fontWeight: '600', color: '#003595' }}>MyOme Personal Genome</div>
          <div style={{ fontSize: '14px' }}>{testRequest.requisition_id}</div>
        </div>
      </div>
      <div className='order_bottom_half'>
        <DataDisplayCell
          dataLabel='Order Date'
          dataValue={formatDate(testRequest.submitted_at)}
          dataLabelStyles={{ fontWeight: '600' }}
          dataValueTestid='orderDate'
        />
        <DataDisplayCell
          dataLabel='Report Type'
          multiline={true}
          multiLine1={PRODUCT_NAMES[get(testRequest, 'orders[0].product_name', '')]}
          multiLine2={PRODUCT_NAMES[get(testRequest, 'orders[1].product_name', '')]}
          dataLabelStyles={{ fontWeight: '600' }}
          dataValueTestid='reportType'
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontSize: '12px', color: '#5F6267', fontWeight: '600', textAlign: 'left', marginTop: '8px' }}>
            Status
          </div>
          <div
            data-testid='orderStatus'
            className={getDisplayStatus === 'Results Ready' ? 'order_status_cell_success' : 'order_status_cell'}
          >
            {getDisplayStatus}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderCard;
