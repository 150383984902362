import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DefaultButton from '../../components/Button/button';
import DataDisplayCell from '../../components/DataDisplayCell/dataDisplayCell';
import DefaultModal from '../../components/DefaultModal/defaultModal';
import Stepper from '../../components/Stepper/stepper';
import {
  CURES_ACT_LANGUAGE,
  CURES_ACT_MODAL_BUTTONS_INFO,
  ORDER_STATUS_STEPS,
  PRODUCT_NAMES,
  RECEIPT_MODAL_BUTTON_INFO,
} from '../../constants/constants';
import HeaderFooterLayout from '../../layouts/HeaderFooterLayout/headerFooterLayout';
import { fetchGeneticCounselingDetails, fetchRequisitionDetailsById, makeCuresActRelease } from '../../store/actions';
import { Context } from '../../store/store';
import { formatDate, recordGoogleAnalyticsEvent, recordGoogleAnalyticsSend } from '../../utils/helpers';
import './myTests.scss';
import OrderReceipt from './orderReceipt';
import ReceiptTemplate from './receiptTemplate';

function MyTests() {
  const [state, dispatch] = useContext(Context);
  const [showCuresActModal, setShowCuresActModal] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const navigate = useNavigate();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const currentReqId = window.location.pathname.split('/')[2];
  const [gcRequestDetails, setGcRequestDetails] = useState();
  const [gCStatusText, setGcStatusText] = useState('');
  const [gcBtnTitle, setGcBtnTitle] = useState('');
  const [gcBtnLink, setGcBtnLink] = useState('');
  const [gcRedirectUrl, setGcRedirectUrl] = useState('');
  const [gCloading, setGcLoading] = useState(true);
  const [accumulatedOrdersStatus, setAccumulatedOrdersStatus] = useState('');
  const [allReportsCompleted, setAllReportsCompleted] = useState(false);

  function getTestRequests() {
    fetchRequisitionDetailsById(currentReqId).then(res => {
      dispatch({
        type: 'SET_CURRENT_TEST_REQUEST',
        payload: res,
      });
      setOrderStatuses(res);
    });
  }

  function fetchGcDetails() {
    // See what the GC status is:
    setGcLoading(true);
    fetchGeneticCounselingDetails().then(res => {
      if (res) {
        dispatch({
          type: 'SET_GC_ACCESS_CODE',
          payload: res.access_code,
        });
        setGcRequestDetails(res);
      } else {
        setGcRequestDetails(false);
      }
      setGcLoading(false);
    });
  }

  useEffect(() => {
    recordGoogleAnalyticsSend('pageview', '/reports', 'Landing on Reports Page');
    // sets text, button title and link depending on gc status
    document.getElementById('order_receipt_iframe').style.display = 'none';
    getTestRequests();
    fetchGcDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setGcDetailsByStatus = () => {
      if (gcRequestDetails) {
        // if they have NOT scheduled an appointment:
        if (gcRequestDetails.service_type === 'POST_TEST_REVIEW_ONLY') {
          // this means there review is complete and they are ready to book
          if (accumulatedOrdersStatus === 'COMPLETED') {
            setGcStatusText(
              'Your results are available below. If you have any questions after viewing your results, ' +
                'you may schedule a session with a genetic counselor',
            );
            setGcBtnTitle('Request Genetic Counseling');
            setGcBtnLink(`/${currentReqId}/genetic_counseling`);
            return null;
          } else {
            return setGcStatusText('You will be able to request genetic counseling once all of your results are ready');
          }
          // if they have already scheduled an appointment:
        } else if (gcRequestDetails.service_type === 'POST_TEST_COUNSELING') {
          switch (gcRequestDetails.external_status) {
            case 'COMPLETED':
              setGcStatusText(
                'You have completed your genetic counseling session. If you have any further questions,' +
                  ' please contact our support team at support@myome.com',
              );
              break;
            case 'IN_PROGRESS':
              if (gcRequestDetails.appointment_date) {
                setGcStatusText(
                  `You have an upcoming genetic counseling session on ` +
                    `${formatDate(gcRequestDetails.appointment_date)}. You can modify or cancel your ` +
                    `appointment by clicking on the button to your right`,
                );
                setGcBtnTitle('View Details');
                setGcRedirectUrl(gcRequestDetails.appointment_url);
              } else {
                setGcStatusText(
                  'We have initiated a request for a complimentary genetic counseling session with ' +
                    'DNAvisit on your behalf. You should have received an email from them to schedule ' +
                    'your appointment',
                );
              }
              break;
            case 'CANCELED':
              setGcStatusText(
                'Your genetic counseling session appointment has been canceled. If this was not ' +
                  'intentional, please reschedule your appointment',
              );
              setGcBtnTitle('Reschedule');
              setGcBtnLink(`/${currentReqId}/genetic_counseling`);
              break;
            case 'INCOMPLETE':
              setGcStatusText(
                'It looks like you missed your appointment with DNAvisit. Please reschedule your ' +
                  'session if you would like to discuss your results with a genetic counselor',
              );
              setGcBtnTitle('Reschedule');
              setGcBtnLink(`/${currentReqId}/genetic_counseling`);
              break;
            default:
              return '';
          }
        }
      } else {
        return setGcStatusText('You will be able to request genetic counseling once all of your results are ready');
      }
    };

    // once both test request and genetic counseling endpoints have returned, fire the set gc details function
    if (Object.keys(state.currentTestRequest).length && gcRequestDetails !== undefined) {
      setGcDetailsByStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(state.currentTestRequest).length, gcRequestDetails]);

  const handleDownload = () => {
    window.frames.order_receipt_iframe.focus();
    window.frames.order_receipt_iframe.print();
  };

  const getMainDisplayText = () => {
    let text = '';
    switch (accumulatedOrdersStatus) {
      case 'COMPLETED':
        text = 'Your report is available for download';
        break;
      case 'CLINICIAN_REVIEW':
        text = 'Your report is being reviewed by your clinician';
        break;
      case 'CANCELED':
        text = 'Your report has been canceled';
        break;
      case 'DENIED':
        text = 'Your report has been denied';
        break;
      default:
        text = 'Your report will be ready soon';
    }
    return text;
  };

  const getCardSubtext = () => {
    if (accumulatedOrdersStatus === 'COMPLETED') {
      return 'Click the download button to view your report';
    } else if (accumulatedOrdersStatus === 'CLINICIAN_REVIEW') {
      return (
        <div>
          Please see your email for a link to schedule with a counselor. If you would like immediate release of your
          results, click
          <span
            style={{ cursor: 'pointer', color: '#003595', fontWeight: '600', marginLeft: '3px' }}
            onClick={() => setShowCuresActModal(true)}
          >
            here
          </span>
        </div>
      );
    } else if (['CANCELED', 'DENIED'].includes(accumulatedOrdersStatus)) {
      return 'Please contact support for more information';
    } else {
      return 'We will send you an update once your report is ready';
    }
  };

  // for the current requisition, filters through all orders to see if all are completed and sets status
  const setOrderStatuses = currentRequisition => {
    // calculate if all orders are completed
    const completedOrders = currentRequisition.orders.filter(m => m.status === 'COMPLETED');
    setAllReportsCompleted(completedOrders.length === currentRequisition.orders.length);

    // the first orders status is the "accumulated" status of all orders
    setAccumulatedOrdersStatus(currentRequisition.orders[0].status);
  };

  const onModalBtnClick = type => {
    if (type === 'PRINT_RECEIPT') {
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Print Order Receipt Button');
      handleDownload();
      setShowReceiptModal(false);
    } else if (type === 'REQUEST_WITHOUT_COUNSELING') {
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Request Early Release');
      makeCuresActRelease({ requisition_id: currentReqId }).then(() => {
        // make call to fetch requisition status
        fetchRequisitionDetailsById(currentReqId).then(res => {
          dispatch({
            type: 'SET_CURRENT_TEST_REQUEST',
            payload: res,
          });
          setOrderStatuses(res);
        });
      });
      setShowCuresActModal(false);
    } else if (type === 'WAIT_FOR_RESULTS') {
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Wait for Results');
      setShowCuresActModal(false);
    }
  };

  const handleReportDownload = () => {
    fetchRequisitionDetailsById(currentReqId)
      .then(res => {
        const link = document.createElement('a');
        link.download = res.collated_report.description;
        link.href = res.collated_report.uri;
        link.setAttribute('data-testid', 'downloadReportPdf');
        if (!isMobile) link.target = '_blank';
        link.type = 'application/pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <HeaderFooterLayout bodyWidth={'100%'}>
      {gCloading || Object.keys(state.currentTestRequest).length === 0 ? (
        <div
          style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#003595' }}
        >
          <Spinner animation='border' />
        </div>
      ) : (
        <div className='tests_body_wrapper'>
          <div className='blue_banner_top tests_page_cell'>
            <div className='tests_title' data-testid='testsTitle'>
              MyOme Personal Genome
            </div>
            <DefaultButton
              buttonLabel='View Receipt'
              styleClass='secondaryButtonStyling'
              buttonStyling={{
                border: '1px solid #003595',
                background: 'transparent',
                color: '#003595',
                padding: '5px',
                fontSize: '11px',
                minWidth: '100px',
                margin: window.innerWidth < 800 ? '0 0 8px 0' : 0,
              }}
              onClick={() => setShowReceiptModal(true)}
              dataTestId='viewReceiptBtn'
            />
          </div>
          <div className='tests_info_cell'>
            <DataDisplayCell
              dataLabel='Requisition Number'
              dataValue={currentReqId}
              dataLabelStyles={{ fontWeight: '600' }}
            />
            <DataDisplayCell
              dataLabel='Order Date'
              dataValue={formatDate(state.currentTestRequest.submitted_at)}
              dataLabelStyles={{ fontWeight: '600' }}
            />
          </div>
          {ORDER_STATUS_STEPS.find(o => o.step === state.currentTestRequest.orders[0].status) ? (
            <>
              <div style={{ marginTop: '12px' }}>
                <Stepper
                  currentStep={state.currentTestRequest.orders[0].status}
                  primaryColor='#003595'
                  secondaryColor='#36CBD9'
                  disabledColor='#ACAEB2'
                  totalSteps={ORDER_STATUS_STEPS}
                  duoTone={true}
                  containerSize={350}
                  showMobileStepper={true}
                  lineStylingMargin='0 -8px 10px -8px'
                />
              </div>
              <div className='tests_page_divider' style={{ marginTop: '18px' }} />
              <div className='genetic_counseling_wrapper tests_page_cell'>
                <div
                  className='genetic_counseling_cell'
                  style={{ width: accumulatedOrdersStatus === 'COMPLETED' ? '70%' : '100%' }}
                >
                  <div className='tests_secondary_title'>Genetic Counseling</div>
                  <div className='genetic_counseling_status_cell'>
                    <FontAwesomeIcon
                      icon={'fa-regular fa-user-doctor'}
                      style={{ marginRight: '8px', fontSize: '24px', color: '#003595' }}
                    />
                    <div style={{ color: '#5F6267', fontSize: '12px', textAlign: 'left' }}>{gCStatusText}</div>
                  </div>
                </div>
                <div>
                  <DefaultButton
                    buttonLabel={gcBtnTitle}
                    styleClass='secondaryButtonStyling'
                    buttonStyling={{
                      border: '1px solid #003595',
                      background: '#ffffff',
                      color: '#003595',
                      padding: '6px',
                      margin: window.innerWidth < 800 ? '16px 0 0 0' : 0,
                      display: gcBtnTitle ? 'block' : 'none',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', `${gcBtnTitle} Button`);
                      gcRedirectUrl ? (window.location.href = gcRedirectUrl) : navigate(gcBtnLink);
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
          <div className='tests_page_divider' />
          <div className='tests_secondary_title' style={{ textAlign: 'left', margin: '12px 0' }}>
            Reports
          </div>
          <div style={{ border: '1px solid #D9D9D9', borderRadius: '8px', margin: ' 0 18px' }}>
            <div className='reports_card_header tests_page_cell'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon
                  icon={'fa-duotone fa-files'}
                  style={{
                    '--fa-primary-color': '#36CBD9',
                    '--fa-secondary-color': '#003595',
                    '--fa-secondary-opacity': '1',
                    marginRight: '12px',
                    fontSize: '20px',
                  }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left' }}>
                  <div className='reports_main_title'>{getMainDisplayText()}</div>
                  <div className='reports_subtitle'>{getCardSubtext()}</div>
                </div>
              </div>
              <DefaultButton
                buttonLabel='Download Reports'
                styleClass='primaryButtonStyling'
                buttonStyling={{
                  padding: '8px',
                  marginTop: window.innerWidth < 800 ? '8px' : 0,
                  display: allReportsCompleted ? 'block' : 'none',
                  fontSize: '12px',
                  border: 'none',
                }}
                onClick={() => {
                  handleReportDownload();
                }}
                dataTestId='downloadReportsBtn'
              />
            </div>
            <div style={{ borderTop: '1px solid #D9D9D9', textAlign: 'left', padding: '12px 18px' }}>
              <div style={{ color: '#5F6267', fontWeight: '600', fontSize: '14px' }}>Downloads Include:</div>
              <div style={{ margin: '0 0 0 15px', fontSize: '13px', lineHeight: '1.8', color: '#5F6267' }}>
                {state.currentTestRequest.orders.map(ord => {
                  return <div key={ord.order_id}>{PRODUCT_NAMES[ord.product_name]}</div>;
                })}
                <div>
                  DNAvisit Clinical Summary Letter{' '}
                  {accumulatedOrdersStatus === 'CLINICIAN_REVIEW'
                    ? '(Will be released after your counseling appointment)'
                    : ''}
                </div>
                <div>Introduction to Your Reports Letter</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DefaultModal
        show={showCuresActModal}
        modalTitle={'Request Access to Report Without Clinician Review'}
        modalButtons={CURES_ACT_MODAL_BUTTONS_INFO}
        onModalBtnClick={type => onModalBtnClick(type)}
        toggleModal={val => setShowCuresActModal(val)}
      >
        <div style={{ padding: '18px 32px', lineHeight: '2', fontSize: '14px' }}>
          {CURES_ACT_LANGUAGE.PART_ONE}
          <br />
          <span style={{ fontWeight: '700' }}>Note: </span>
          {CURES_ACT_LANGUAGE.PART_TWO}
        </div>
      </DefaultModal>
      <DefaultModal
        show={showReceiptModal}
        modalTitle={null}
        onModalBtnClick={type => onModalBtnClick(type)}
        modalButtons={RECEIPT_MODAL_BUTTON_INFO}
        toggleModal={val => setShowReceiptModal(val)}
      >
        <ReceiptTemplate />
      </DefaultModal>
      <OrderReceipt frame={'order_receipt_iframe'} />
    </HeaderFooterLayout>
  );
}

export default MyTests;
