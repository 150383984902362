import get from 'lodash/get';
import { useContext, useEffect, useState } from 'react';

import { ReactComponent as FullLogoSvg } from '@statics/images/full_logo.svg';
import { PRODUCT_NAMES } from '../../constants/constants';
import { fetchPaymentDetails } from '../../store/actions';
import { Context } from '../../store/store';
import { formatDate } from '../../utils/helpers';

const paymentStatusMap = {
  FREE: 'Free',
  SUCCEEDED: 'Complete',
  PENDING: 'Pending',
  CANCELED: 'Canceled',
};

function ReceiptTemplate() {
  const [state] = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    charge_date: null, // 2024-02-15
    payment_status: null, // FREE, PENDING, SUCCEEDED, CANCELED
    total_cost: null, // float in dollars
  });

  const getUserName = () => {
    const nameObj = state.currentTestRequest.subject.name;
    return nameObj.first + ' ' + nameObj.last;
  };

  const getPaymentStatus = () => {
    return paymentStatusMap[paymentInfo.payment_status];
  };

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const response = await fetchPaymentDetails(state.currentTestRequest.requisition_id);
        setPaymentInfo(response);
      } catch {
        setError(true);
      }
      setLoading(false);
    };
    if (Object.keys(state.currentTestRequest).length) {
      setLoading(true);
      setError(false);
      fetchPayment();
    }
  }, [state.currentTestRequest]);

  return (
    <div data-testid='receiptTemplateWrapper'>
      {!Object.keys(state.currentTestRequest).length || loading ? (
        <div style={{ textAlign: 'center' }}>Generating Receipt...</div>
      ) : error ? (
        <div style={{ textAlign: 'center' }}>Error Generating Receipt. Please try again later.</div>
      ) : (
        <div>
          <div style={{ fontSize: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FullLogoSvg alt='logo' width={'200px'} />
            </div>
            <div style={{ marginTop: '18px', borderRadius: '8px' }}>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '28px',
                  fontWeight: 700,
                  marginBottom: '24px',
                  borderBottom: '1px solid #ACAEB2',
                  paddingBottom: '24px',
                }}
              >
                Thank you for your order
              </div>
              <div>
                <div style={{ fontSize: '22px', fontWeight: '600' }}>Order Summary</div>
                <div data-testid='orderSummarySection' style={{ padding: '0 24px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '18px' }}>
                    <div>
                      <div style={{ fontWeight: '700' }}>Requisition ID:</div>
                      <div>{state.currentTestRequest.requisition_id}</div>
                    </div>
                    <div>
                      <div style={{ fontWeight: '700' }}>Order Date:</div>
                      <div>{formatDate(state.currentTestRequest.submitted_at)}</div>
                    </div>
                  </div>
                  <div style={{ marginTop: '18px' }}>
                    <div style={{ fontWeight: '700', marginBottom: '8px' }}>Order Details:</div>
                    <div>1 x MyOme Personal Genome</div>
                    <ul style={{ color: '#5F6267' }}>
                      <li>{PRODUCT_NAMES[get(state.currentTestRequest, 'orders[0].product_name', '')]}</li>
                      <li>{PRODUCT_NAMES[get(state.currentTestRequest, 'orders[1].product_name', '')]}</li>
                      <li>Clinician order review with DNAvisit</li>
                    </ul>
                  </div>
                </div>
                <div style={{ fontSize: '22px', fontWeight: '600', marginTop: '32px' }}>Shipping Details</div>
                <div
                  data-testid='shippingDetailsSection'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #ACAEB2',
                    padding: '18px 18px 24px 18px',
                  }}
                >
                  <div>
                    <div style={{ fontWeight: '700' }}>Name:</div>
                    <div>{getUserName()}</div>
                  </div>
                  <div>
                    <div style={{ fontWeight: '700', marginBottom: '8px' }}>Shipping Address</div>
                    <div>{get(state.currentTestRequest.subject, 'contact_info[0].address.street_address', '')}</div>
                    <div>{get(state.currentTestRequest.subject, 'contact_info[0].address.city', '')}</div>
                    <div>
                      <span style={{ marginRight: '3px' }}>
                        {get(state.currentTestRequest.subject, 'contact_info[0].address.region', '')},
                      </span>
                      <span style={{ marginRight: '3px' }}>
                        {get(state.currentTestRequest.subject, 'contact_info[0].address.postal_code', '')}
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ fontWeight: '700', marginTop: '18px', paddingLeft: '24px' }}>
                  {paymentInfo.payment_status !== 'FREE' ? (
                    <div style={{ fontSize: '12px' }}>Payment is {getPaymentStatus()}</div>
                  ) : null}
                  <div style={{ fontSize: '18px' }}>Total: ${Number(paymentInfo.total_cost).toFixed(2)}</div>
                  {paymentInfo.payment_status === 'SUCCEEDED' && paymentInfo.charge_date ? (
                    <div style={{ fontSize: '14px' }}>
                      Charge completed on: {new Date(paymentInfo.charge_date).toLocaleDateString()}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReceiptTemplate;
