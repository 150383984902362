import Select, { components } from 'react-select';
import formInputStyles from '../scss/formInput.module.scss';
import dropdownStyles from './formDropdown.module.scss';
import { useEffect, useState } from 'react';

function FormDropdown({ onClick, dataTestId, label, options, clinicianError, error, errorText, defaultValue, width }) {
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    // TODO: make eslint happy
  }, [clinicianError]);

  const handleSelected = option => {
    setSelected(option.value);
    onClick(option.value);
  };

  const filterOption = (option, inputValue) => {
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  return (
    <div
      data-sentry-block
      className={formInputStyles.form_input_spacing}
      style={{ width: window.innerWidth > 1020 ? width : '100%' }}
    >
      <div className={formInputStyles.label_styling}>{label}</div>
      <Select
        options={options}
        className={clinicianError || error ? dropdownStyles.dropdownToggleError : dropdownStyles.dropdownToggleStyling}
        classNames={{
          control: state => (state.isFocused ? dropdownStyles.controlFocused : dropdownStyles.controlUnfocused),
        }}
        style={{ width: width || '100%' }}
        defaultValue={selected}
        onChange={handleSelected}
        filterOption={filterOption}
        components={{
          SelectContainer: ({ children, ...props }) => (
            <components.SelectContainer {...props} innerProps={{ ...props.innerProps, 'data-testid': dataTestId }}>
              {children}
            </components.SelectContainer>
          ),
        }}
      />
      {error ? <div style={{ fontSize: '10px', color: 'red', textAlign: 'left' }}>{errorText}</div> : null}
    </div>
  );
}

export default FormDropdown;
