import './dataDisplayCell.scss';
import classNames from 'classnames';

function DataDisplayCell({
  dataLabel,
  dataValue,
  additionalClass,
  multiline,
  multiLine1,
  multiLine2,
  multiLine3,
  dataLabelStyles,
  dataValueTestid,
}) {
  return (
    <div className={classNames('data_display_wrapper', additionalClass || null)}>
      <div className='data_label' style={dataLabelStyles}>
        {dataLabel}
      </div>
      {!multiline ? (
        <div className='data_value' data-testid={dataValueTestid}>
          {dataValue}
        </div>
      ) : (
        <div className='data_value' data-testid={dataValueTestid}>
          <div>{multiLine1}</div>
          <div>{multiLine2}</div>
          <div>{multiLine3}</div>
        </div>
      )}
    </div>
  );
}

export default DataDisplayCell;
