import './header.scss';
import { Nav, Navbar } from 'react-bootstrap';

import { ReactComponent as FullLogoSvg } from '@statics/images/full_logo.svg';
import UserLogout from '../UserLogout/userLogout';
import { isLoggedIn, recordGoogleAnalyticsEvent } from '../../utils/helpers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

function Header() {
  const [activeKey, setActiveKey] = useState('home');
  const navigate = useNavigate();

  useEffect(() => {
    setActiveKey(window.location.pathname);
  }, [activeKey]);

  const onNavClick = route => {
    recordGoogleAnalyticsEvent('User Interaction', 'Button Click', `Nav Link Route: ${route}`);
    navigate(route);
  };

  return (
    <Navbar collapseOnSelect expand='lg' className='header-wrapper' onSelect={selectedKey => setActiveKey(selectedKey)}>
      <Navbar.Brand>
        <FullLogoSvg alt='logo' title='logo' className='logo-styling' onClick={() => onNavClick('/home')} />
      </Navbar.Brand>
      {isLoggedIn() ? <Navbar.Toggle aria-controls='responsive-navbar-nav' /> : null}
      {isLoggedIn() ? (
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto mobile_navbar_list'>
            <Nav.Link
              className={classNames('nav_link_styling', activeKey === '/home' ? 'nav_link_active' : '')}
              onClick={() => onNavClick('/home')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={classNames('nav_link_styling', activeKey.includes('reports') ? 'nav_link_active' : '')}
              onClick={() => onNavClick(`/reports/${sessionStorage.getItem('currentRQ')}`)}
            >
              My Reports
            </Nav.Link>
            <Nav.Link
              className={classNames('nav_link_styling', activeKey === '/account' ? 'nav_link_active' : '')}
              onClick={() => onNavClick('/account')}
            >
              My Account
            </Nav.Link>
          </Nav>
          <Navbar.Text className='justify-content-end navbar_user_logout'>
            <UserLogout />
          </Navbar.Text>
        </Navbar.Collapse>
      ) : null}
    </Navbar>
  );
}

export default Header;
