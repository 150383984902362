import { useState } from 'react';
import { createPortal } from 'react-dom';

function IFrame({ children, title, name, id }) {
  const [ref, setRef] = useState();
  const container = ref?.contentWindow?.document?.body;

  return (
    <iframe ref={setRef} title={title} name={name} id={id}>
      {container && createPortal(children, container)}
    </iframe>
  );
}

export default IFrame;
