import React from 'react';
import './maskInput.scss';
import MaskedInput from 'react-text-mask';
import { createTextMaskInputElement } from 'text-mask-core';

import classNames from 'classnames';

const MaskedFormInput = ({
  mask,
  label,
  error,
  errorText,
  onBlur,
  onChange,
  dataTestId,
  id,
  isDisabled,
  value,
  width,
}) => {
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current) {
      const textMaskInputElement = createTextMaskInputElement({
        inputElement: inputRef.current,
        mask,
      });
      textMaskInputElement.update(value);
    }
  }, [mask, value]);

  return (
    <div className='form_input_spacing' style={{ width: window.innerWidth > 1020 ? width : '100%' }}>
      <div className={classNames('label_styling', isDisabled ? 'disabled-text' : null)}>{label}</div>
      <MaskedInput
        className={error ? 'mask_input_error' : 'mask_input_wrapper'}
        mask={mask}
        onBlur={onBlur}
        onChange={onChange}
        data-testid={dataTestId}
        id={id}
        disabled={isDisabled}
        ref={ref => {
          inputRef.current = ref ? ref.inputElement : null;
        }}
      />
      {error ? <div style={{ fontSize: '10px', color: 'red', textAlign: 'left' }}>{errorText}</div> : null}
    </div>
  );
};

export default MaskedFormInput;
