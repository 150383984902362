import './settings.scss';
import '../../stylesheets/common.scss';

import { ReactComponent as FullLogoSvg } from '@statics/images/full_logo.svg';
import HeaderFooterLayout from '../../layouts/HeaderFooterLayout/headerFooterLayout';
import DataDisplayCell from '../../components/DataDisplayCell/dataDisplayCell';
import { Form, Tab, Tabs, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import {
  fetchUserID,
  fetchUserInfo,
  fetchUsersConsents,
  updateUserConsent,
  fetchAllConsents,
} from '../../store/actions';
import get from 'lodash/get';
import { firstLetterCapsOnly, recordGoogleAnalyticsSend } from '../../utils/helpers';
import { PRODUCT_NOTIFICATIONS_CONSENT } from '../../constants/constants';
import { marked } from 'marked';

function Settings() {
  const [userInfo, setUserInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [consents, setConsents] = useState([]);
  const [productNotificationConsentStatus, setProductNotificationConsentStatus] = useState(false);

  marked.use({
    mangle: false,
    headerIds: false,
  });

  useEffect(() => {
    recordGoogleAnalyticsSend('pageview', '/account', 'Landing on My Accounts Page');
    setLoading(true);
    // first get the user ID:
    fetchUserID().then(res => {
      fetchUserInfo(res).then(res => {
        setUserInfo(res);
        setLoading(false);
      });
    });
    fetchUsersConsents().then(res => {
      if (res.consents) {
        // remove product consent from list of consents to display
        const productConsentIndex = res.consents
          .map(c => c.consent_uri)
          .findIndex(i => i === PRODUCT_NOTIFICATIONS_CONSENT);
        const productConsent = res.consents.splice(productConsentIndex, 1)[0];

        setProductNotificationConsentStatus(productConsent.is_active); // take the status of the consent
        setConsents(res.consents);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConsentToggle = () => {
    const consentUris = consents.map(m => m.consent_uri);
    const payloadConsents = { consents_granted: [...consentUris], consents_revoked: [] };
    /* if the product notification consent is CURRENTLY true that means user has selected
            to OPT-OUT so we add product notification consent to consents_revoked array */
    if (productNotificationConsentStatus) {
      payloadConsents.consents_revoked.push(PRODUCT_NOTIFICATIONS_CONSENT);
    } else {
      // else we add it to consents_granted
      payloadConsents.consents_granted.push(PRODUCT_NOTIFICATIONS_CONSENT);
    }

    updateUserConsent(payloadConsents).then(() => {
      // update the toggle value to be opposite of what is was before
      setProductNotificationConsentStatus(!productNotificationConsentStatus);
    });
  };

  const renderConsentText = consentName => {
    // fetch consents from the BE:
    fetchAllConsents(['GENERAL', 'PR-2019']).then(response => {
      if (response && response.length) {
        const consentByName = response.find(c => c.name === consentName);

        const newWindow = window.open('', '_blank');
        if (newWindow) {
          newWindow.document.body.innerHTML = `
          <div>
            ${(<FullLogoSvg alt='myome' style='width: 130px; height: 40px;' />)}
            <div style="padding: 18px 32px; font-size: 14px; font-family: Roboto;
                border: 1px solid #ACAEB2; border-radius: 8px; margin: 0 auto;
                width: 900px; font-size: 14px">
                ${marked.parse(consentByName.consent_text)}
            </div>
          <div>
        `;
        }
      }
    });
  };

  return (
    <HeaderFooterLayout>
      {loading ? (
        <div
          style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#003595' }}
        >
          <Spinner animation='border' />
        </div>
      ) : (
        <div className='header_footer_layout_body'>
          <div className='settings_page_title'>My Account</div>
          <div className='settings_title_subtext'>
            If any of this information is inaccurate, please contact us at clinical@myome.com
          </div>
          <div className='settings_card_body_wrapper'>
            <div className='card'>
              <div style={{ fontWeight: '600', fontSize: '18px', textAlign: 'left' }}>Patient Information</div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='settings_data_display'>
                  <DataDisplayCell
                    additionalClass='data_display_top_margin'
                    dataLabel='First Name'
                    dataValue={get(userInfo, 'name.first', '')}
                  />
                  <DataDisplayCell
                    additionalClass='data_display_top_margin'
                    dataLabel='Phone Number'
                    dataValue={get(userInfo, 'contact_info[0].phone', '')}
                  />
                  <DataDisplayCell
                    additionalClass='data_display_top_margin'
                    dataLabel='Shipping Address'
                    multiline={true}
                    multiLine1={
                      get(userInfo, 'contact_info[0].address.street_address', '') +
                      ', ' +
                      get(userInfo, 'contact_info[0].address.city', '')
                    }
                    multiLine2={
                      get(userInfo, 'contact_info[0].address.region', '') +
                      ', ' +
                      get(userInfo, 'contact_info[0].address.postal_code', '')
                    }
                  />
                </div>
                <div className='settings_data_display'>
                  <DataDisplayCell
                    additionalClass='data_display_top_margin'
                    dataLabel='Last Name'
                    dataValue={get(userInfo, 'name.last', '')}
                  />
                  <DataDisplayCell
                    additionalClass='data_display_top_margin'
                    dataLabel='Email'
                    dataValue={get(userInfo, 'contact_info[0].email', '')}
                  />
                </div>
                <div className='settings_data_display'>
                  <DataDisplayCell
                    additionalClass='data_display_top_margin'
                    dataLabel='Sex'
                    dataValue={firstLetterCapsOnly(get(userInfo, 'demographics.sex', ''))}
                  />
                  <DataDisplayCell
                    additionalClass='data_display_top_margin'
                    dataLabel='Birth Date'
                    dataValue={get(userInfo, 'demographics.dob', '')}
                  />
                </div>
              </div>
            </div>
            <div className='card data_display_top_margin'>
              <Tabs defaultActiveKey='preferences' className='mb-3' style={{ fontSize: '14px' }}>
                <Tab eventKey='preferences' title='Preferences' className='settings_tab'>
                  <div className='settings_switch_wrapper' style={{ maxWidth: '490px' }}>
                    <Form.Check
                      className='settings_switch'
                      type='switch'
                      id='custom-switch'
                      checked={productNotificationConsentStatus}
                      onChange={onConsentToggle}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: '8px',
                        textAlign: 'left',
                      }}
                    >
                      <div className='settings_consent_title'>Contact me with information about MyOme</div>
                      <div style={{ fontSize: '12px', fontWeight: '400' }}>
                        I’d like to opt in to receive marking other promotional information from MyOme
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='consents' title='Consents' className='settings_tab'>
                  <div style={{ paddingLeft: '24px', maxWidth: '490px' }}>
                    {consents.map((c, i) => {
                      return (
                        <div className='settings_consent_cell' key={i}>
                          <div className='settings_consent_title'>{c.name}</div>
                          <div style={{ fontSize: '12px', fontWeight: '400' }}>
                            I agree to the terms and conditions provided in the
                            <span
                              style={{ color: '#003595', cursor: 'pointer', marginLeft: '3px' }}
                              onClick={() => renderConsentText(c.name)}
                            >
                              {c.name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </HeaderFooterLayout>
  );
}

export default Settings;
