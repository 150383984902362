import { Modal } from 'react-bootstrap';
import DefaultButton from '../Button/button';

function DefaultModal({ show, modalTitle, children, modalButtons, toggleModal, onModalBtnClick }) {
  return (
    <Modal show={show} onHide={() => toggleModal(false)}>
      <Modal.Header closeButton>
        {modalTitle ? (
          <Modal.Title style={{ fontSize: window.innerWidth < 560 ? '12px' : '18px' }}>{modalTitle}</Modal.Title>
        ) : null}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        {modalButtons.map((btn, i) => {
          return (
            <div key={i}>
              <DefaultButton
                buttonLabel={btn.buttonLabel}
                styleClass={btn.styleClass}
                buttonStyling={btn.buttonStyling}
                onClick={() => onModalBtnClick(btn.btnType)}
                dataTestId={btn.dataTestId}
              />
            </div>
          );
        })}
      </Modal.Footer>
    </Modal>
  );
}

export default DefaultModal;
