import { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from './routes.js';

import Keycloak from 'keycloak-js';
import { envInfo, KEYCLOAK_CLIENT_ID, KEYCLOAK_URL } from '../constants/constants.js';
import { Context } from '../store/store.js';

function RouterProvider() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useContext(Context);

  const keycloakInstance = new Keycloak({
    url: KEYCLOAK_URL,
    realm: envInfo.keycloak_realm,
    clientId: KEYCLOAK_CLIENT_ID,
  });

  useEffect(() => {
    const initKeycloak = async () => {
      keycloakInstance
        .init({
          onLoad: 'check-sso',
          pkceMethod: 'S256',
          silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
        })
        .then(() => {
          setLoading(false);
        });

      keycloakInstance.onAuthSuccess = function () {
        localStorage.setItem('access_token', keycloakInstance.token);
        setAuthenticated(keycloakInstance.authenticated);
      };

      dispatch({ type: 'SET_KEYCLOAK', payload: keycloakInstance });

      keycloakInstance.onTokenExpired = () => {
        keycloakInstance
          .updateToken(30)
          .then(() => {
            localStorage.setItem('access_token', keycloakInstance.token);
          })
          .catch(() => {
            localStorage.removeItem('access_token');
            setAuthenticated(false);
            window.location.href = window.location.origin + '/login';
          });
      };
    };

    initKeycloak();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div style={{ color: '#003595', fontSize: '24px', fontWeight: '600', marginTop: '18px' }}>Loading...</div>;
  } else {
    return (
      <Routes>
        {routes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={
              // Render the component only if authentication is not required or the user is logged in
              !route.auth || authenticated ? route.element : <Navigate to={route.redirect} replace={true} />
            }
          />
        ))}
      </Routes>
    );
  }
}

export default RouterProvider;
