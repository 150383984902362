import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import DefaultButton from '../../components/Button/button';
import { PRODUCT_NAMES } from '../../constants/constants';
import HeaderFooterLayout from '../../layouts/HeaderFooterLayout/headerFooterLayout';
import gcImage from '../../statics/images/genetic_counseling.jpg';
import { fetchGeneticCounselingDetails, fetchRequisitionDetailsById } from '../../store/actions';
import { Context } from '../../store/store';
import './geneticCounseling.scss';

function GeneticCounseling() {
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const currentReqId = window.location.pathname.split('/')[1];
  const copyNotificationRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    if (!state.gcAccessCode) {
      fetchGeneticCounselingDetails().then(res => {
        dispatch({
          type: 'SET_GC_ACCESS_CODE',
          payload: res.access_code,
        });
        setLoading(false);
      });
    }
    fetchRequisitionDetailsById(currentReqId).then(res => {
      dispatch({
        type: 'SET_CURRENT_TEST_REQUEST',
        payload: res,
      });
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let testNames = [];
  if (Object.keys(state.currentTestRequest).length) {
    testNames = state.currentTestRequest.orders.map(o => o.product_name);
  }

  const showCopyNotification = () => {
    copyNotificationRef.current.style.visibility = 'visible';
    setTimeout(function () {
      if (copyNotificationRef.current) copyNotificationRef.current.style.visibility = 'hidden';
    }, 3000);
  };

  return (
    <HeaderFooterLayout bodyWidth={'100%'}>
      {!state.gcAccessCode && !state.testRequests.length ? null : (
        <div>
          <div className='gc_body_wrapper'>
            <div className='top_img_banner'>
              <img src={gcImage} style={{ width: '100%', height: 'inherit' }} alt='genetic_counseling' />
              <div className='gc_overlay'>
                <div className='gc_banner_header'>Expert genetic guidance awaits</div>
                <div className='gc_banner_subtext'>
                  Understanding your genetics can be complicated. We have partnered with DNAvisit to provide
                  complimentary genetic counseling to get the most out of your MyOme Personal Genome experience
                </div>
              </div>
            </div>
            <div className='gc_bottom_body'>
              <div style={{ margin: '22px 0', alignSelf: 'center' }}>
                <div className='gc_header_text' style={{ fontSize: '1.5em' }}>
                  Genetic Counseling
                </div>
                <div className='gc_subtext'>Receive post-genetic counseling at no additional cost to you</div>
              </div>
              <div className='gc_card_wrapper'>
                <div style={{ borderBottom: '1px solid #D9D9D9', padding: '24px' }}>
                  <div style={{ display: 'flex' }}>
                    <FontAwesomeIcon
                      icon={'fa-regular fa-calendar-circle-user'}
                      style={{ color: '#003595', fontSize: '18px', margin: '5px 8px 0 0' }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div className='gc_header_text'>Schedule your appointment</div>
                      <div className='gc_subtext'>
                        Use your one time access code to schedule your appointment by clicking the button below
                      </div>
                      <div style={{ width: '100%' }}>
                        <div
                          ref={copyNotificationRef}
                          style={{
                            color: '#5F6267',
                            fontSize: '12px',
                            visibility: 'hidden',
                            fontWeight: '600',
                            textAlign: 'left',
                            marginTop: '8px',
                          }}
                        >
                          Copied!
                        </div>
                        <div className='gc_access_token_wrapper'>
                          <div
                            className='gc_access_code_wrapper'
                            onClick={() => {
                              navigator.clipboard.writeText(state.gcAccessCode);
                              showCopyNotification();
                            }}
                          >
                            <div>{state.gcAccessCode}</div>
                            <FontAwesomeIcon
                              icon={'fa-regular fa-copy'}
                              style={{ color: '#003595', fontSize: '14px', cursor: 'pointer' }}
                            />
                          </div>
                          <DefaultButton
                            buttonLabel={'Schedule now'}
                            styleClass={'secondaryButtonStyling'}
                            buttonStyling={{
                              minWidth: '100px',
                              alignSelf: 'center',
                              padding: '3px 5px',
                              margin: window.innerWidth < 800 ? '16px 0 0 0' : 0,
                            }}
                            onClick={() => (window.location.href = 'https://www.DNAvisit.com/appointment')}
                            disabled={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ padding: '24px' }}>
                  <div style={{ display: 'flex' }}>
                    <FontAwesomeIcon
                      icon={'fa-regular fa-files-medical'}
                      style={{ fontSize: '18px', color: '#003595', margin: '5px 8px 0 0' }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div className='gc_header_text'>Available Reports</div>
                      <div className='gc_subtext'>
                        You will be receiving genetic counseling for the reports listed below
                      </div>
                    </div>
                  </div>
                  <div>
                    {testNames.length ? (
                      <div className='gc_tests_display_cell'>
                        {testNames.map((test, i) => {
                          return (
                            <ul key={i} style={{ margin: '0' }}>
                              <li>{PRODUCT_NAMES[test]}</li>
                            </ul>
                          );
                        })}
                      </div>
                    ) : (
                      <div className='gc_tests_display_cell' style={{ marginLeft: '24px' }}>
                        There are currently no reports available
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </HeaderFooterLayout>
  );
}

export default GeneticCounseling;
