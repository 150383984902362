import { createContext, useReducer } from 'react';
import Reducer from './reducer.js';

export const Context = createContext();

export const initialState = {
  testRequests: [],
  currentTestRequest: {},
  keycloak: {},
  gcAccessCode: '',
  stripePaymentInfo: {
    original_cost: 849.0,
    total_cost: 849.0,
  },
  testRequestInfo: {
    billing: [
      {
        billing_type: 'SELF',
        stripe_paymentintent_id: '',
      },
    ],
    subject: {
      contact_info: [
        {
          address: {
            city: '',
            country: '',
            postal_code: '',
            region: '',
            street_address: '',
            street_address2: '',
          },
          phone: '',
          email: '',
          confirmEmail: '',
        },
      ],
      demographics: {
        dob: '',
        sex: '',
      },
      name: {
        first: '',
        last: '',
        middle: '',
      },
    },
    family_history: {
      QNR_FHX_1: {
        question_text:
          'Do you have multiple individuals on the same side of your family with the same cancer?' +
          ' Example: A mother and maternal aunt with breast cancer.',
        response: '',
      },
      QNR_FHX_2: {
        question_text:
          'Do you or immediate family members (parents, siblings, or children)' +
          ' have a history of any of the following cancers diagnosed under 50 years of age:' +
          ' breast, colon, uterine/endometrial, gastric, renal/kidney, urinary tract, or prostate?',
        response: '',
      },
      QNR_FHX_3: {
        question_text:
          'Do you or immediate family members (parents, siblings, or children)' +
          ' have a history of any of the following cancers at any age: pancreatic cancer, ovarian cancer' +
          ' (including fallopian tube or primary peritoneal cancer),' +
          ' medullary thyroid cancer, pheochromocytoma, triple negative breast cancer, or male breast cancer?',
        response: '',
      },
      QNR_FHX_4: {
        question_text:
          'Do you or immediate family members (parents, siblings, or children)' +
          ' have a history of any of the following conditions under 50 years of age:' +
          ' cardiac arrest, heart failure, heart transplant, heart attack, stroke, aortic aneurysm/dissection,' +
          ' or unexplained death?',
        response: '',
      },
      QNR_FHX_5: {
        question_text:
          'Do you or immediate family members (parents, siblings, or children)' +
          ' have a history of any of the following conditions at any age: extremely high LDL cholesterol' +
          ' (≥190 in adults or ≥130 in children/adolescents)?',
        response: '',
      },
      QNR_FHX_8: {
        question_text:
          'Do you or any biological family members have a known inherited condition related to cancer, ' +
          'heart problems, or other genetic concerns related to this test?',
        response: '',
      },
      QNR_FHX_9: {
        question_text: 'Have you or any family members had prior findings on genetic testing related to this test?',
        response: '',
      },
    },
    consents_granted: [],
    order: {
      product_ids: ['PR-2019', 'PR-3009'],
    },
  },
  userInfo: {},
};

export const Store = ({ children, testDispatch, testState }) => {
  const [iState, iDispatch] = useReducer(Reducer, initialState);
  const dispatch = testDispatch || iDispatch;
  const state = testState || iState;

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export default Store;
