import '@fortawesome/fontawesome-pro/css/all.css';
import './App.scss';

import RouterProvider from './router/routesProvider.js';
import Store from './store/store.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleCheck,
  faCreditCard,
  faEnvelope,
  faFileLines,
  faHospital,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import { faBars, faCheck, faLaptopMedical, faPencil } from '@fortawesome/free-solid-svg-icons';
import {
  faBoxCheck,
  faDna,
  faFileChartColumn,
  faFiles,
  faHospitalUser,
  faHourglassClock,
  faListCheck,
  faMagnifyingGlassChart,
  faMemoCircleCheck,
  faReceipt,
  faUserDoctorMessage,
  faVialCircleCheck,
} from '@fortawesome/pro-duotone-svg-icons';
import { faFilePlusMinus } from '@fortawesome/pro-light-svg-icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  faCalendarCircleUser,
  faCopy,
  faFilesMedical,
  faMessageLines,
  faUserCheck,
  faUserDoctor,
} from '@fortawesome/pro-regular-svg-icons';
import ReactGA from 'react-ga4';

import { envInfo } from './constants/constants.js';

library.add(
  faUser,
  faHospital,
  faVialCircleCheck,
  faReceipt,
  faHospitalUser,
  faPencil,
  faCheck,
  faListCheck,
  faUserDoctor,
  faLaptopMedical,
  faEnvelope,
  faBars,
  faCircleCheck,
  faCreditCard,
  faFileLines,
  faBoxCheck,
  faMemoCircleCheck,
  faHourglassClock,
  faMagnifyingGlassChart,
  faUserDoctorMessage,
  faFileChartColumn,
  faFilePlusMinus,
  faDna,
  faMessageLines,
  faFiles,
  faUserCheck,
  faCalendarCircleUser,
  faFilesMedical,
  faCopy,
);

ReactGA.initialize(envInfo.ga_id);
const stripe = loadStripe(envInfo.stripe_api_key).catch(error => {
  console.warn(error);
  return null;
});

function App() {
  return (
    <div className='App'>
      <Elements stripe={stripe}>
        <Store>
          <RouterProvider />
        </Store>
      </Elements>
    </div>
  );
}

export default App;
