import './userLogout.scss';

import { useContext, useEffect, useState } from 'react';
import { Context } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserLogout() {
  const [state] = useContext(Context);
  const [userFirstName, setUserFirstName] = useState('');

  function handleLogout() {
    state.keycloak.logout({ redirectUri: window.location.origin + '/login' });
    localStorage.clear();
  }

  useEffect(() => {
    if (state.keycloak.tokenParsed) {
      setUserFirstName(state.keycloak.tokenParsed.given_name);
    } else {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.keycloak.tokenParsed]);

  return (
    <div className='user_logout_wrapper'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon
          icon={'fa-regular fa-user'}
          style={{ color: '#003595', fontSize: '20px', marginRight: '5px' }}
        />
        <span style={{ color: '#003595', fontWeight: '500', fontSize: '18px' }}>Hello {userFirstName}</span>
      </div>
      <div
        onClick={handleLogout}
        style={{ fontSize: '12px', display: 'flex', justifyContent: 'end', cursor: 'pointer' }}
      >
        Logout
      </div>
    </div>
  );
}

export default UserLogout;
