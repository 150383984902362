import './addressSearch.scss';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

function AddressSearch({
  inputRef,
  width,
  isDisabled,
  label,
  error,
  errorText,
  id,
  defaultValue,
  name,
  required,
  onBlur,
  placeholder,
  onChange,
  dataTestId,
}) {
  return (
    <div style={{ width: window.innerWidth > 1020 ? width : '100%' }} className='form_input_spacing'>
      <div className={classNames('label_styling', isDisabled ? 'disabled-text' : null)}>{label}</div>
      <Form.Group>
        <Form.Control
          id={id}
          defaultValue={defaultValue}
          name={name}
          required={required}
          type='text'
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={isDisabled}
          data-testid={dataTestId}
          style={error ? { border: '1px solid red', borderRadius: '4px' } : null}
          ref={inputRef}
          onChange={onChange}
        />
      </Form.Group>
      {error ? <div style={{ fontSize: '10px', color: 'red', textAlign: 'left' }}>{errorText}</div> : null}
    </div>
  );
}

export default AddressSearch;
