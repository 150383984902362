import './titleCard.scss';

function TitleCard({ title, children, height }) {
  return (
    <div className='title_card_wrapper' style={{ height: height || 'auto' }}>
      <div className='title_card_title'>{title}</div>
      {children}
    </div>
  );
}

export default TitleCard;
