import '../../stylesheets/common.scss';
import './home.scss';

import get from 'lodash/get';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/button';
import OrderCard from '../../components/Cards/OrderCard/orderCard';
import TitleCard from '../../components/Cards/TitleCard/titleCard';
import DataDisplayCell from '../../components/DataDisplayCell/dataDisplayCell';
import HeaderFooterLayout from '../../layouts/HeaderFooterLayout/headerFooterLayout';
import { fetchUserID, fetchUserInfo, fetchUserTestRequests } from '../../store/actions';
import { Context } from '../../store/store';
import { recordGoogleAnalyticsEvent, recordGoogleAnalyticsSend } from '../../utils/helpers';

function Home() {
  const [userInfo, setUserInfo] = useState();
  const [userIdLoading, setUserIdLoading] = useState(true);
  const [testRequestLoading, setTestRequestLoading] = useState(true);
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  const loading = useMemo(() => userIdLoading || testRequestLoading, [userIdLoading, testRequestLoading]);

  useEffect(() => {
    recordGoogleAnalyticsSend('pageview', '/home', 'Landing on Home Page');
    // first get the user ID:
    fetchUserID().then(res => {
      sessionStorage.setItem('user_id', res);
      localStorage.setItem('user_id', res);
      fetchUserInfo(res).then(res => {
        setUserInfo(res);
        setUserIdLoading(false);
      });
    });
    fetchUserTestRequests().then(res => {
      if (res.results) {
        dispatch({
          type: 'SET_ALL_TEST_REQUESTS',
          payload: res.results,
        });
        sessionStorage.setItem('currentRQ', res.results[res.results.length - 1].requisition_id);
        setTestRequestLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userFirstName = get(userInfo, 'name.first', '');
  const userLastName = get(userInfo, 'name.last', '');

  const createNameAvatar = () => {
    if (userFirstName && userLastName) {
      return userFirstName.charAt(0) + userLastName.charAt(0);
    }
  };

  return (
    <HeaderFooterLayout>
      {loading ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            color: '#003595',
            alignItems: 'center',
          }}
        >
          <div>
            <Spinner animation='border' />
          </div>
        </div>
      ) : (
        <div className='header_footer_layout_body'>
          <div className='home_page_title'>Welcome {userFirstName}!</div>
          <div className='home_body_wrapper'>
            <div className='home_body_top'>
              <TitleCard title='Your Reports' height={window.innerWidth > 1020 ? '345px' : 'auto'}>
                <div style={{ textAlign: 'left', fontSize: '12px', marginTop: '5px', color: '#5F6267' }}>
                  Click on the card to view details
                </div>
                <div style={{ marginTop: '28px' }}>
                  <OrderCard
                    testRequest={state.testRequests[state.testRequests.length - 1]}
                    onClick={() => {
                      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Order Card Click');
                      navigate(`/reports/${state.testRequests[state.testRequests.length - 1].requisition_id}`);
                    }}
                  />
                </div>
              </TitleCard>
            </div>
            <div className='home_body_bottom'>
              <div className='home_user_card'>
                <div className='name_avatar'>{createNameAvatar()}</div>
                <div style={{ fontSize: '22px', fontWeight: '600', marginTop: '18px' }}>
                  {userFirstName + ' ' + userLastName}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    margin: '18px 0 23px 0',
                    width: '100%',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DataDisplayCell dataLabel='Email' dataValue={get(userInfo, 'contact_info[0].email', '')} />
                    <DataDisplayCell dataLabel='Sex' dataValue={get(userInfo, 'demographics.sex', '')} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DataDisplayCell dataLabel='Phone' dataValue={get(userInfo, 'contact_info[0].phone', '')} />
                    <DataDisplayCell dataLabel='Birthdate' dataValue={get(userInfo, 'demographics.dob', '')} />
                  </div>
                </div>
                <Button
                  buttonLabel='View Account Details'
                  styleClass='secondaryButtonStyling'
                  buttonStyling={{ padding: '8px' }}
                  onClick={() => {
                    recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'View Account Details Button');
                    navigate('/account');
                  }}
                />
              </div>
              <TitleCard title='Contact Us'>
                <div
                  style={{ width: '255px', color: '#7C7E83', fontSize: '12px', textAlign: 'start', marginTop: '8px' }}
                >
                  Got questions? We have you covered. Send us an email at support@myome.com and a member of our team
                  will reach out to you
                </div>
              </TitleCard>
            </div>
          </div>
        </div>
      )}
    </HeaderFooterLayout>
  );
}

export default Home;
