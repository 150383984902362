/* eslint-disable camelcase */

import ReactGA from 'react-ga4';

export const isLoggedIn = () => {
  return !!localStorage.getItem('access_token');
};

export const firstLetterCapsOnly = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatDate = value => {
  const date = new Date(value);
  const formatted = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  return formatted;
};

export const recordGoogleAnalyticsEvent = (category, action, label) => {
  return ReactGA.event({
    category,
    action,
    label,
  });
};

export const recordGoogleAnalyticsSend = (hitType, page, title) => {
  return ReactGA.send({
    hitType,
    page,
    title,
  });
};
