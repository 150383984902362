import './radioSelectionBars.scss';
import { Field, FieldArray } from 'formik';
import classNames from 'classnames';

const RadioSelectionBars = ({
  name,
  type,
  options,
  direction,
  showBorder,
  hideHoverStyle,
  isDisabled,
  onChange,
  formik,
  index,
}) => {
  return (
    <FieldArray
      name={name}
      render={() => (
        <div style={{ display: 'flex', flexDirection: direction }} data-testid={index}>
          {options.map((op, i) => (
            <label
              key={i}
              className={classNames(
                'radio-selection-wrapper',
                hideHoverStyle ? null : 'radio-selection-hover',
                showBorder ? 'radio-selection-border' : null,
                direction === 'row' ? 'radio-right-margin' : 'radio-bottom-margin',
                isDisabled ? 'selection-bar-disabled' : null,
              )}
            >
              <Field
                type={type}
                name={name}
                value={op.value}
                style={{ marginRight: '18px' }}
                checked={formik.values.family_history[index].response === op.value}
                onChange={e => onChange(e.target.value)}
              />
              {op.displayValue}
              {op.showRecommended ? (
                <span style={{ fontSize: '12px', color: '#7C7E83', marginLeft: '12px' }}>(Recommended)</span>
              ) : (
                ''
              )}
            </label>
          ))}
        </div>
      )}
    ></FieldArray>
  );
};

export default RadioSelectionBars;
