import './pit_styling.scss';

import DefaultButton from '../../components/Button/button';
import FormDropdown from '../../components/FormDropdown/formDropdown';
import FormInput from '../../components/FormInput/formInput';
import MaskedFormInput from '../../components/MaskInput/maskInput';
import PromoCode from '../../components/PromoCode/promoCode';
import personalGenome from '../../statics/images/personal_genome.png';

import { getIn, useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { GENDER, envInfo } from '../../constants/constants';

import { Context } from '../../store/store';
import {
  firstLetterCapsOnly,
  isLoggedIn,
  recordGoogleAnalyticsEvent,
  recordGoogleAnalyticsSend,
} from '../../utils/helpers';

import { fetchPaymentIntent } from '../../store/actions';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLoadScript } from '@react-google-maps/api';
import AddressSearch from '../../components/AddressSearch/addressSearch';

import classNames from 'classnames';

const libraries = ['places'];

function OrderCheckout({ onBtnClick }) {
  const [state, dispatch] = useContext(Context);
  const elements = useElements();
  const stripe = useStripe();
  const [boneMarrowTransplant, setHasBoneMarrowTransplant] = useState(false);
  const [excludedState, setExcludedState] = useState(false);
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userLoggedIn, setUserLoggedIn] = useState(!!state.keycloak.idTokenParsed);
  const [stripeError, setStripeError] = useState('');
  const inputRef = useRef(null);
  const dobDivRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: envInfo.googleMapsApiKey,
    libraries,
  });

  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1 < 10 ? '0' + (dateObj.getUTCMonth() + 1) : dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate() < 10 ? '0' + dateObj.getUTCDate() : dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  const formattedMaxDate = year + '-' + month + '-' + day;

  const emailComparison = value => {
    return value.toLowerCase().includes('@myome.com');
  };

  const workEmailError = () => {
    const userEmail = formik.values.subject.contact_info[0].email;
    if (userEmail) {
      return emailComparison(userEmail);
    }
  };

  const formik = useFormik({
    initialValues: {
      billing: state.testRequestInfo.billing,
      subject: state.testRequestInfo.subject,
    },
    validationSchema: Yup.object({
      subject: Yup.object({
        contact_info: Yup.array().of(
          Yup.object().shape({
            address: Yup.object({
              city: Yup.string().required('Required'),
              postal_code: Yup.string()
                .required('Required')
                .matches(/^[0-9]+$/, 'Invalid Zip Code')
                .length(5, 'Invalid Zip Code'),
              region: Yup.string().required('Required'),
              street_address: Yup.string().required('Required'),
            }),
            phone: Yup.string()
              .required('Required')
              .test('valid-phone-number', 'Invalid Phone Number', function (value) {
                const chars = ['_', '-', '(', ')', ' '];
                const justNumbers = value.split('').filter(m => !chars.includes(m));
                return justNumbers.length === 10;
              }),
            email: Yup.string()
              .email('Invalid email')
              .required('Required')
              .test('email', 'Please use personal email address', function (value) {
                return !emailComparison(value);
              })
              .test('email', 'Invalid email', function (value) {
                const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                return emailRegex.test(value);
              }),
            confirmEmail: Yup.string()
              .email('Invalid email')
              .required('Confirm Email is required')
              .test('email-match', 'Emails do not match', function (value) {
                return value === this.resolve(Yup.ref('email'));
              }),
          }),
        ),
        demographics: Yup.object({
          dob: Yup.string()
            .required('Required')
            .test('invalid-date', 'Invalid date', function (value) {
              const dateArr = value.split('-');
              const year = dateArr[0];
              return Number(year) >= 1900;
            }),
          sex: Yup.string().required('Required'),
        }),
        name: Yup.object({
          first: Yup.string().required('Required'),
          last: Yup.string().required('Required'),
        }),
      }),
    }),
    onSubmit: async values => {
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Next Button: Test Selection');
      // attach apartment number to street address if its there
      if (values.subject.contact_info[0].address.street_address2) {
        values.subject.contact_info[0].address.street_address +=
          ' #' + values.subject.contact_info[0].address.street_address2;
      }
      if (state.testRequestInfo.billing && state.testRequestInfo.billing[0].stripe_paymentintent_id) {
        onBtnClick(values);
      } else {
        // handle stripe here and then send values to parent:
        const stripeResponse = await handleStripeConfirmCardPayment();
        if (!stripeResponse.error) {
          onBtnClick(values);
        } else {
          window.scroll(0, 0);
        }
      }
    },
  });

  useEffect(() => {
    if (userLoggedIn) {
      formik.setFieldValue('subject.name.first', state.keycloak.idTokenParsed.given_name, true);
      formik.setFieldValue('subject.name.last', state.keycloak.idTokenParsed.family_name, true);
      formik.setFieldValue('subject.contact_info[0].email', state.keycloak.idTokenParsed.email, true);
      formik.setFieldValue('subject.contact_info[0].confirmEmail', state.keycloak.idTokenParsed.email, true);
    }
    if (isLoaded) {
      recordGoogleAnalyticsSend('pageview', '/order?step=checkout', 'PIT Flow: Test Selection');
      if (window.google) {
        const autocompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
          types: ['address'],
        });

        autocompleteInstance.addListener('place_changed', () => {
          const selectedPlaceObj = autocompleteInstance.getPlace();
          if (selectedPlaceObj) {
            let streetAddress = '';
            const streetNumber = selectedPlaceObj.address_components.find(obj => obj.types.includes('street_number'));
            const streetName = selectedPlaceObj.address_components.find(obj => obj.types.includes('route'));
            const city = selectedPlaceObj.address_components.find(obj => obj.types.includes('locality'));
            const region = selectedPlaceObj.address_components.find(obj =>
              obj.types.includes('administrative_area_level_1'),
            );
            const postalCode = selectedPlaceObj.address_components.find(obj => obj.types.includes('postal_code'));
            if (streetNumber) streetAddress += streetNumber.long_name + ' ';
            if (streetName) streetAddress += streetName.short_name;
            inputRef.current.value = streetAddress;
            // if address is from NY, show error and do not populate:
            const state = selectedPlaceObj.address_components.find(obj =>
              obj.types.includes('administrative_area_level_1'),
            ).short_name;
            if (state !== 'NY') {
              formik.setFieldValue('subject.contact_info[0].address.street_address', streetAddress, true);
              formik.setFieldValue('subject.contact_info[0].address.city', city ? city.long_name : '', true);
              formik.setFieldValue('subject.contact_info[0].address.region', region ? region.short_name : '', true);
              formik.setFieldValue(
                'subject.contact_info[0].address.postal_code',
                postalCode ? postalCode.short_name : '',
                true,
              );
              setExcludedState(false);
            } else {
              setExcludedState(true);
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, userLoggedIn]);

  const handleSubmitPromoCode = async promoCode => {
    const paymentInfo = await handleStripeIntentUpdate(promoCode);
    return paymentInfo.promo_code_error;
  };

  const handleStripeIntentUpdate = async promoCode => {
    try {
      const payload = {};
      if (formik.values.subject.contact_info[0].email) {
        payload.email = formik.values.subject.contact_info[0].email;
      }
      if (state.stripePaymentInfo.payment_intent_id) {
        payload.payment_intent = state.stripePaymentInfo.payment_intent_id;
      }
      if (promoCode !== undefined) {
        if (promoCode) {
          // check for an empty string when clearing
          payload.promo_code = promoCode;
        }
      } else {
        if (formik.values.billing[0].promo_code) {
          payload.promo_code = formik.values.billing[0].promo_code;
        }
      }
      if (state.testRequestInfo.order.product_ids) {
        payload.products = state.testRequestInfo.order.product_ids;
      }
      const paymentInfo = await fetchPaymentIntent(payload);
      dispatch({
        type: 'SET_STRIPE_PAYMENT_INFO',
        payload: paymentInfo,
      });
      if (payload.promo_code && !paymentInfo.promo_code_error) {
        formik.values.billing[0].promo_code = payload.promo_code;
      } else {
        delete formik.values.billing[0].promo_code;
      }
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: {
          ...state.testRequestInfo,
          billing: [...formik.values.billing],
        },
      });
      return paymentInfo;
    } catch (error) {
      setStripeError(error);
    }
  };

  const handleStripeConfirmCardPayment = async () => {
    setLoader(true);
    // if we don't have a paymentIntent, fetch one
    let paymentIntentClientSecret = state.stripePaymentInfo.payment_intent_client_secret;
    let paymentIntentId = state.stripePaymentInfo.payment_intent_id;
    let totalCost = state.stripePaymentInfo.total_cost;
    if (!paymentIntentClientSecret) {
      const paymentInfo = await handleStripeIntentUpdate();
      paymentIntentClientSecret = paymentInfo.payment_intent_client_secret;
      paymentIntentId = paymentInfo.payment_intent_id;
      totalCost = paymentInfo.total_cost;
    }

    if (totalCost > 50) {
      // tests 50 cents and under are free
      // Add Card to Payment Intent
      const paymentIntentResponse = await stripe.confirmCardPayment(paymentIntentClientSecret, {
        payment_method: { card: elements.getElement(CardElement) },
      });
      if (paymentIntentResponse.error) {
        setStripeError(paymentIntentResponse.error.message);
        setLoader(false);
        return paymentIntentResponse;
      }
    }

    formik.values.billing[0].stripe_paymentintent_id = paymentIntentId;
    dispatch({
      type: 'SET_REQUEST_INFO',
      payload: {
        ...state.testRequestInfo,
        billing: [...formik.values.billing],
      },
    });
    setLoader(false);
    setStripeError('');
    return {};
  };

  const displayNextBtn = () => {
    return (
      <div className='checkout_btn_wrapper'>
        <DefaultButton
          disabled={loader}
          type='submit'
          buttonLabel='Next'
          styleClass='primaryButtonStyling'
          onClick={handleOnNext}
          dataTestId='orderCheckoutNextBtn'
        />
      </div>
    );
  };

  function handleLogout() {
    state.keycloak.logout({ redirectUri: window.location.origin + '/order?step=checkout' });
    localStorage.clear();
  }

  const handleOnNext = async () => {
    if (!boneMarrowTransplant && !isInvalidBirthDate() && !excludedState && !workEmailError()) {
      formik.handleSubmit();
    } else {
      const dobDiv = dobDivRef.current;
      if (dobDiv) dobDiv.scrollIntoView();
    }
  };

  const isInvalidBirthDate = () => {
    const birthDate = formik.values.subject.demographics.dob;
    const [birthYear, birthMonth, birthDay] = birthDate.split('-');
    const currentDate = new Date();
    const [currentYear, currentMonth, currentDay] = [
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate(),
    ];
    let age = currentYear - birthYear;
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--;
    }
    return age < 18;
  };

  const displayAuthenticationActions = () => {
    if (!isLoggedIn()) {
      return (
        <div className='signup_logged_in_wrapper'>
          <div className='order_checkout_header order_checkout_header_spacing'>Sign Up</div>
          <div style={{ fontSize: '12px' }} className='order_checkout_header_spacing'>
            Already have an account?
            <span
              style={{ fontWeight: '600', color: '#003595', marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => state.keycloak.login({ redirectUri: window.location.origin + '/order?step=checkout' })}
            >
              Login
            </span>
          </div>
          <div className='sign_up_wrapper'>
            <div className='order_input_cell order_checkout_header_spacing' style={{ marginTop: '18px' }}>
              <FormInput
                value={formik.values.subject.contact_info[0].email}
                onChange={formik.handleChange}
                label={'Email'}
                width={'70%'}
                dataTestId={'subject.contact_info[0].email'}
                id={'subject.contact_info[0].email'}
                onBlur={formik.handleBlur}
                error={
                  getIn(formik.touched, 'subject.contact_info[0].email') &&
                  getIn(formik.errors, 'subject.contact_info[0].email')
                }
                errorText={getIn(formik.errors, 'subject.contact_info[0].email')}
              />
              <FormInput
                value={formik.values.subject.contact_info[0].confirmEmail}
                onChange={formik.handleChange}
                label={'Confirm Email'}
                width={'70%'}
                dataTestId={'subject.contact_info[0].confirmEmail'}
                id={'subject.contact_info[0].confirmEmail'}
                onBlur={formik.handleBlur}
                error={
                  getIn(formik.touched, 'subject.contact_info[0].confirmEmail') &&
                  getIn(formik.errors, 'subject.contact_info[0].confirmEmail')
                }
                errorText={getIn(formik.errors, 'subject.contact_info[0].confirmEmail')}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className='order_checkout_header_spacing'>
          <div className='order_checkout_header' style={{ textAlign: 'left' }}>
            Welcome Back
          </div>
          <div style={{ fontSize: '14px', textAlign: 'left', marginTop: '8px' }}>
            You are logged in as
            <div style={{ fontWeight: '600', fontSize: '16px' }}>
              {formik.values.subject.name.first && formik.values.subject.name.last ? (
                <span>{formik.values.subject.name.first + ' ' + formik.values.subject.name.last}</span>
              ) : null}
              {formik.values.subject.contact_info[0].email ? (
                <span style={{ marginLeft: '5px' }}>({formik.values.subject.contact_info[0].email})</span>
              ) : null}
            </div>
            {workEmailError() ? (
              <div className='required_error_styling'>Please use your personal email address</div>
            ) : null}
          </div>
          <DefaultButton
            buttonLabel='Not you? Logout'
            styleClass='secondaryButtonStyling'
            buttonStyling={{ width: '40px', padding: '8px', marginTop: '18px' }}
            onClick={handleLogout}
          />
        </div>
      );
    }
  };

  const displayErrorByCriteria = (errorCriteria, marginLeftValue, errorText) => {
    return (
      <div>
        {errorCriteria ? (
          <div style={{ fontSize: '12px', marginLeft: marginLeftValue }} className='required_error_styling'>
            {errorText}
          </div>
        ) : null}
      </div>
    );
  };

  const displayBillingInput = () => {
    return (
      <div>
        <div className='subtext_styling' style={{ margin: '2px 0 10px 0', fontStyle: 'italic' }}>
          Your card will not be charged until your order has been reviewed by one of our clinical experts
        </div>
        <form
          style={{
            width: '100%',
            border: stripeError ? '1px solid red' : '',
            borderRadius: '8px',
            marginBottom: '5px',
          }}
        >
          <CardElement
            id='test-stripe-card'
            className={classNames(
              'custom_stripe_styling',
              state.testRequestInfo.billing && state.testRequestInfo.billing[0].stripe_paymentintent_id
                ? 'disabled_stripe_card'
                : '',
            )}
          />
        </form>
        {displayErrorByCriteria(stripeError, '0px', stripeError)}
        <div className='subtext_styling' style={{ marginTop: '10px' }}>
          We also accept HSA/FSA payments
        </div>
      </div>
    );
  };

  const displayBillingInfoSaved = () => {
    return (
      <div
        style={{
          background: '#FFFFFF',
          borderRadius: '8px',
          padding: '18px',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '18px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <FontAwesomeIcon
            icon={'fa-regular fa-credit-card'}
            style={{ color: '#003595', fontSize: '22px', marginRight: '8px' }}
          />
          <p style={{ color: '#003595', fontSize: '18px', fontWeight: '700', marginTop: '2px', marginBottom: 0 }}>
            Card Info Saved
          </p>
        </div>
        <p style={{ color: '#5F6267', fontSize: '13px', display: 'inline-block' }}>
          You won&apos;t be charged until your test has been approved.
        </p>
      </div>
    );
  };

  const displayStripeUnavailable = () => {
    return (
      <div
        style={{
          background: '#FFFFFF',
          borderRadius: '8px',
          padding: '18px',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '18px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <FontAwesomeIcon
            icon={'fa-regular fa-credit-card'}
            style={{ color: '#003595', fontSize: '22px', marginRight: '8px' }}
          />
          <p style={{ color: '#003595', fontSize: '18px', fontWeight: '700', marginTop: '2px', marginBottom: 0 }}>
            Credit Card Entry Unavailable
          </p>
        </div>
        <p style={{ color: '#5F6267', fontSize: '13px', display: 'inline-block' }}>
          Please try disabling your extensions to enable credit card entry.
        </p>
      </div>
    );
  };

  const onNameChange = e => {
    const newVal = e.target.value;
    e.target.value = newVal.charAt(0).toUpperCase() + newVal.slice(1);
    formik.handleChange(e);
  };

  const handleAddressOnChange = e => {
    formik.setFieldValue('subject.contact_info[0].address.street_address', e.target.value, true);
  };

  return (
    <div className='order_checkout_wrapper'>
      <div className='checkout_left_side'>
        <div className='user_form_wrapper'>
          {displayAuthenticationActions()}
          <div className='order_cell_divider' />
          <div className='order_checkout_header order_checkout_header_spacing'>Patient Information</div>
          <div className='form_inputs_section'>
            <div className='order_input_cell' ref={dobDivRef}>
              <FormInput
                value={formik.values.subject.name.first}
                onChange={onNameChange}
                label={'First Name'}
                width={'175px'}
                name={'subject.name.first'}
                dataTestId={'subject.name.first'}
                id={'subject.name.first'}
                isDisabled={userLoggedIn && formik.values.subject.name.first}
                onBlur={formik.handleBlur}
                error={getIn(formik.touched, 'subject.name.first') && getIn(formik.errors, 'subject.name.first')}
                errorText={getIn(formik.errors, 'subject.name.first')}
              />
              <FormInput
                value={formik.values.subject.name.middle}
                onChange={onNameChange}
                label={'Middle Name'}
                width={'175px'}
                id={'subject.name.middle'}
              />
              <FormInput
                value={formik.values.subject.name.last}
                isDisabled={userLoggedIn && formik.values.subject.name.last}
                onChange={onNameChange}
                label={'Last Name'}
                name={'subject.name.last'}
                width={'175px'}
                dataTestId={'subject.name.last'}
                id={'subject.name.last'}
                onBlur={formik.handleBlur}
                error={getIn(formik.touched, 'subject.name.last') && getIn(formik.errors, 'subject.name.last')}
                errorText={getIn(formik.errors, 'subject.name.last')}
              />
            </div>
            <div className='order_input_cell' style={{ alignItems: 'baseline' }}>
              <FormInput
                value={formik.values.subject.demographics.dob}
                onChange={formik.handleChange}
                label={'Date of Birth'}
                type='date'
                width={'175px'}
                dataTestId={'subject.demographics.dob'}
                id={'subject.demographics.dob'}
                placeholder='YYYY-MM-DD'
                max={formattedMaxDate}
                min={'1900-01-01'}
                pattern='\d{4}-\d{2}-\d{2}'
                error={
                  getIn(formik.touched, 'subject.demographics.dob') && getIn(formik.errors, 'subject.demographics.dob')
                }
                errorText={getIn(formik.errors, 'subject.demographics.dob')}
              />
              {window.innerWidth < 1020
                ? displayErrorByCriteria(
                    isInvalidBirthDate(),
                    '0',
                    'We cannot accept orders from individuals under 18 years old',
                  )
                : null}
              <FormDropdown
                defaultValue={
                  formik.values.subject.demographics.sex
                    ? [
                        {
                          value: formik.values.subject.demographics.sex,
                          label: firstLetterCapsOnly(formik.values.subject.demographics.sex),
                        },
                      ]
                    : []
                }
                onClick={selectedOption => formik.handleChange('subject.demographics.sex')(selectedOption)}
                label={'Sex'}
                options={GENDER}
                width={'175px'}
                dataTestId={'subject.demographics.sex'}
                error={
                  getIn(formik.touched, 'subject.demographics.sex') && getIn(formik.errors, 'subject.demographics.sex')
                }
                errorText={getIn(formik.errors, 'subject.demographics.sex')}
              />
              <MaskedFormInput
                value={formik.values.subject.contact_info[0].phone}
                mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                label='Phone Number'
                onChange={formik.handleChange}
                width={'175px'}
                onBlur={formik.handleBlur}
                dataTestId={'subject.contact_info[0].phone'}
                id={'subject.contact_info[0].phone'}
                error={
                  getIn(formik.touched, 'subject.contact_info[0].phone') &&
                  getIn(formik.errors, 'subject.contact_info[0].phone')
                }
                errorText={getIn(formik.errors, 'subject.contact_info[0].phone')}
              />
            </div>
            {window.innerWidth > 1020
              ? displayErrorByCriteria(
                  isInvalidBirthDate(),
                  '12px',
                  'We cannot accept orders from individuals under 18 years old',
                )
              : null}
            <Form.Group className='checkout_radio_wrapper' data-sentry-block>
              <span style={{ marginRight: '8px' }}>Have you ever had a bone marrow transplant?</span>
              <Form.Check
                checked={boneMarrowTransplant === true}
                type='radio'
                label='Yes'
                name={boneMarrowTransplant.toString()}
                onChange={() => setHasBoneMarrowTransplant(true)}
              />
              <Form.Check
                style={{ marginLeft: '8px' }}
                checked={boneMarrowTransplant === false}
                type='radio'
                label='No'
                name={boneMarrowTransplant.toString()}
                onChange={() => setHasBoneMarrowTransplant(false)}
              />
            </Form.Group>
            {boneMarrowTransplant ? (
              <div
                style={{ marginLeft: window.innerWidth < '1020' ? '0' : '12px', fontSize: '12px' }}
                className='required_error_styling'
              >
                We cannot accept orders from individuals who have received transplants.
              </div>
            ) : null}
          </div>
          <div className='order_cell_divider' />
          <div className='order_checkout_header order_checkout_header_spacing'>Shipping Information</div>
          <span className='order_checkout_header_spacing' style={{ fontSize: '12px', color: '#5F6267' }}>
            Search and select an address below
          </span>
          <div className='form_inputs_section'>
            <div className='order_input_cell'>
              <AddressSearch
                defaultValue={formik.values.subject.contact_info[0].address.street_address}
                dataTestId={'subject.contact_info[0].address.street_address'}
                id={'subject.contact_info[0].address.street_address'}
                inputRef={inputRef}
                label={'Street Address'}
                width={'70%'}
                onChange={handleAddressOnChange}
                placeholder={' '}
                error={
                  getIn(formik.touched, 'subject.contact_info[0].address.street_address') &&
                  getIn(formik.errors, 'subject.contact_info[0].address.street_address')
                }
                errorText={getIn(formik.errors, 'subject.contact_info[0].address.street_address')}
              />
              {window.innerWidth < 1020
                ? displayErrorByCriteria(
                    excludedState,
                    '0',
                    'Due to local regulations we do not yet offer testing in your state',
                  )
                : null}
              <FormInput
                value={formik.values.subject.contact_info[0].address.street_address2}
                onChange={formik.handleChange}
                label={'Apt No'}
                width={'30%'}
                id={'subject.contact_info[0].address.street_address2'}
              />
            </div>
            {window.innerWidth > 1020
              ? displayErrorByCriteria(
                  excludedState,
                  '12px',
                  'Due to local regulations we do not yet offer testing in your state',
                )
              : null}
            <div className='order_input_cell'>
              <FormInput
                value={formik.values.subject.contact_info[0].address.city}
                onChange={formik.handleChange}
                label={'City'}
                width={'200px'}
                onBlur={formik.handleBlur}
                dataTestId={'subject.contact_info[0].address.city'}
                id={'subject.contact_info[0].address.city'}
                error={
                  getIn(formik.errors, 'subject.contact_info[0].address.city') &&
                  getIn(formik.touched, 'subject.contact_info[0].address.city')
                }
                errorText={getIn(formik.errors, 'subject.contact_info[0].address.city')}
              />
              <FormInput
                value={formik.values.subject.contact_info[0].address.region}
                onChange={formik.handleChange}
                label={'State'}
                width={'175px'}
                onBlur={formik.handleBlur}
                dataTestId={'subject.contact_info[0].address.region'}
                id={'subject.contact_info[0].address.region'}
                error={
                  getIn(formik.touched, 'subject.contact_info[0].address.region') &&
                  getIn(formik.errors, 'subject.contact_info[0].address.region')
                }
                errorText={getIn(formik.errors, 'subject.contact_info[0].address.region')}
              />
              <FormInput
                value={formik.values.subject.contact_info[0].address.postal_code}
                onChange={formik.handleChange}
                label={'Zip Code'}
                width={'175px'}
                onBlur={formik.handleBlur}
                dataTestId={'subject.contact_info[0].address.postal_code'}
                id={'subject.contact_info[0].address.postal_code'}
                error={
                  getIn(formik.touched, 'subject.contact_info[0].address.postal_code') &&
                  getIn(formik.errors, 'subject.contact_info[0].address.postal_code')
                }
                errorText={getIn(formik.errors, 'subject.contact_info[0].address.postal_code')}
              />
            </div>
          </div>
        </div>
        {window.innerWidth < 1020 ? displayNextBtn() : null}
      </div>
      <div className='checkout_right_side'>
        <div className='billing_checkout_wrapper'>
          <div className='order_checkout_header' style={{ textAlign: 'left' }}>
            Your Test Selection
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '18px' }}>
            <div>
              <img src={personalGenome} alt='logo' className='logo-styling checkout-genome-logo' />
            </div>
            <div style={{ fontWeight: '700', fontSize: '16px', color: '#5F6267', marginTop: '10px' }}>
              ${Number(state.stripePaymentInfo.original_cost).toFixed(2)}
            </div>
          </div>
          <ul style={{ fontSize: '14px', lineHeight: '1.9', textAlign: 'left', margin: '8px 0 0 15px' }}>
            <li>Clinical grade 30x whole-genome DNA sequencing that can be reanalyzed for future tests with MyOme</li>
            <li>Proactive Health Report, 81 Genes</li>
            <li>Pharmacogenomics Report</li>
            <li>Clinician order review with DNAvisit</li>
            <li>Post-test genetic counseling to help you understand your results</li>
            <li>Access to your results through MyOme’s secure patient portal</li>
          </ul>
          <hr></hr>
          <PromoCode
            disabled={!!formik.values.billing[0].stripe_paymentintent_id}
            initialValue={formik.values.billing[0].promo_code}
            onSubmit={handleSubmitPromoCode}
          ></PromoCode>
          <hr></hr>
          <div style={{ display: 'contents' }}>
            {state.stripePaymentInfo.discount ? (
              <>
                <div>
                  <div style={{ float: 'left' }}>Subtotal</div>
                  <div style={{ float: 'right', marginRight: '3rem' }}>
                    ${Number(state.stripePaymentInfo.original_cost).toFixed(2)}
                  </div>
                </div>
                <div>
                  <div style={{ float: 'left' }}>Discount</div>
                  <div style={{ float: 'right', marginRight: '3rem' }} data-testid='stripeDiscount'>
                    {state.stripePaymentInfo.discount}
                  </div>
                </div>
              </>
            ) : null}
            <div>
              <div style={{ float: 'left' }}>
                <b>Total</b>
              </div>
              <div style={{ float: 'right', marginRight: '3rem' }}>
                <b>${Number(state.stripePaymentInfo.total_cost).toFixed(2)}</b>
              </div>
            </div>
          </div>
          {state.stripePaymentInfo.total_cost > 0.5 ? (
            <>
              <hr></hr>
              <div className='billing_info_wrapper'>
                <div className='order_checkout_header'>Billing Information</div>
                {stripe && elements
                  ? state.testRequestInfo.billing && state.testRequestInfo.billing[0].stripe_paymentintent_id
                    ? displayBillingInfoSaved()
                    : displayBillingInput()
                  : displayStripeUnavailable()}
              </div>
            </>
          ) : null}
        </div>
        {window.innerWidth > 1020 ? displayNextBtn() : null}
      </div>
    </div>
  );
}

export default OrderCheckout;
