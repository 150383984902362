import ErrorPage from '../pages/Error/errorPage';
import GeneticCounseling from '../pages/GeneticCounseling/geneticCounseling';
import Home from '../pages/Home/home';
import Login from '../pages/Login/login';
import MyTests from '../pages/MyTests/myTests';
import Settings from '../pages/Settings/settings';
import PitFlowOrchestrator from '../pages/patientInitatedTesting/pitFlowOrchestrator';

const routes = [
  {
    path: '/order',
    element: <PitFlowOrchestrator />,
  },
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/home',
    element: <Home />,
    auth: true,
    redirect: '/login',
  },
  {
    path: '/account',
    element: <Settings />,
    auth: true,
    redirect: '/login',
  },
  {
    path: '/reports/:requisition_id',
    element: <MyTests />,
    auth: true,
    redirect: '/login',
  },
  {
    path: '/:requisition_id/genetic_counseling',
    element: <GeneticCounseling />,
    auth: true,
    redirect: '/login',
  },
  {
    path: 'order/error',
    element: (
      <ErrorPage
        errorHeader='Drat! An error occured'
        primarySubtext='Please try your request again.'
        secondarySubtext='If the problem recurs, contact support@myome.com.'
        tertiarySubtext="We're sorry for the inconvenience."
        rerouteUrl='/login'
        buttonLabel='Go Back'
      />
    ),
  },
  {
    path: '/error',
    element: (
      <ErrorPage
        errorHeader='Drat! An error occured'
        primarySubtext='Please try your request again.'
        secondarySubtext='If the problem recurs, contact support@myome.com.'
        tertiarySubtext="We're sorry for the inconvenience."
        rerouteUrl='/home'
        buttonLabel='Back to home'
      />
    ),
  },
  {
    path: '*',
    element: (
      <ErrorPage
        errorHeader='Page not found'
        primarySubtext='Looks like the page you are looking for is not here.'
        secondarySubtext='If the problem recurs, contact support@myome.com.'
        tertiarySubtext=''
        rerouteUrl='/login'
        buttonLabel='Back to home'
      />
    ),
  },
];

export default routes;
