import OrderFrame from '../../components/IFrame/iFrame.jsx';
import './orderReceipt.scss';
import ReceiptTemplate from './receiptTemplate';

const OrderReceipt = ({ frame }) => {
  return (
    <OrderFrame id={frame} title={frame} name={frame}>
      {<ReceiptTemplate />}
    </OrderFrame>
  );
};

export default OrderReceipt;
